import React, { useState } from "react";
import { about } from "../../../assets/about";
import yearData from "../../../data/yeardata";
import { useMediaQuery } from "@react-hook/media-query";
const HowItStarted = () => {
  const [selectedYear, setSelectedYear] = useState(2018); // Initial selected year
  const isSmallScreen = useMediaQuery("(max-width: 639px)");  
  const handleYearClick = (year) => {
    setSelectedYear(year);
  };

  return (
    <div className="h-auto relative pt-4   md:px-28 px-[40px] text-white bg-gradient-to-r from-[#088FC7] to-[#612980]">
      {/* Text */}
      <div className=" text-white mb-12">
        <h1 className="md:text-5xl text-2xl font-extrabold">How It Started</h1>
        <p className="md:text-xl font-semibold mt-2">
          Dynamic growth and discovery
        </p>
      </div>

      <div className="md:flex md:flex-row md:items-center md:justify-center gap-28 md:pb-16 pb-[30px] w-[100%]">
        <div className="lg:w-[50%] w-full">
        <img
          src={yearData.find((data) => data.year === selectedYear)?.image}
          alt={`img${selectedYear}`}
          className="md:h-[300px]  rounded-3xl"
        />
        </div>

        <div className="lg:w-[50%] w-full">
          <h1 className="2xl:text-4xl md:text-3xl font-bold mb-8 mt-4">
            {selectedYear}
          </h1>
          {yearData
            .find((data) => data.year === selectedYear)
            ?.paragraphs.map((paragraph, index) => (
              <p
                key={index}
                className="2xl:text-xl md:text-lg font-normal mb-2 text-justify"
              >
                {paragraph}
              </p>
            ))}
        </div>
      </div>

      <div className="relative flex items-start justify-between md:px-5 md:pb-28 pb-[15px] md:pt-10 pt-8 2xl:border-t-8 border-t-4">
        <img
          src={about.logo}
          alt="Background"
          className={`z-20 md:h-20 h-[60px] w-[60px] md:w-20 absolute rounded-full top-0 -mt-10`}
          style={{
            left: `calc(${(selectedYear - 2018) * 15}% - ${isSmallScreen ? '75px' : '40px'} + 112px - 50px)`,
            marginTop: "-40px", // Adjust this value as needed
          }}
        />

        {yearData.map((data, index) => (
          <div
            key={index} // Make sure to add a unique key prop when mapping arrays
            className={`text-white md:text-2xl font-semibold cursor-pointer ${
              selectedYear === data.year ? "font-bold" : ""
            }`}
            onClick={() => handleYearClick(data.year)}
          >
            {data.year}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItStarted;
