export const services = {
   coding: require("./services/Coding.png"),
   coding2: require("./services/Coding2.png"),
   responsive: require("./services/Responsive.png"),
   responsive2: require("./services/Responsive2.png"),
   ol: require("./services/ol.png"),
   ol2: require("./services/ol2.png"),
   external: require("./services/External.png"),
   external2: require("./services/External2.png"),
   bg: require("./Frame 1000003796.png"),
   custom: require("./custom.png"),
   webdev: require("./webdev.png"),
   consult: require("./consult.png"),
   responsive3: require("./resposive.png"),
   servicesbg: require("./servicesbg.png"),
   streamline:require("./services/streamline.png"),
   streamline2:require("./services/streamline2.png"),
   api:require("./services/api.png"),
   api2:require("./services/api2.png"),
   testing:require("./services/testing.png"),
   testing2:require("./services/testing2.png"),
   ui:require("./services/ui.png"),
   ui2:require("./services/ui2.png"),
   product:require("./services/product.png"),
   product2:require("./services/product2.png"),
   ux:require("./services/ux.png"),
   ux2:require("./services/ux2.png"),
   user:require("./services/user.png"),
   user2:require("./services/user2.png"),
   market_research: require("./research.png"),
   uidesign: require("./ui.png"),
   uxdesign: require("./ux.png"),
   roadmap:require("./roadmap.png"),
   backend: require("./backend.png"),
   uiuxextra:require("./uiuxextra.png"),



}