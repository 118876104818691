import React from "react";
import { logogrid, uiuxLogos } from "../../../data/logogrid";
import { useProject } from "../../../context/selectedServiceContext";
import { services } from "../../../assets/services";
import { contact } from "../../../assets/contact";
import { Navigate } from "react-router-dom";

const SectionFour = () => {
  const { selectedProject } = useProject();

  if (!selectedProject) {
    return <Navigate to="/" />;
  }

  // Based on the selected project category, determine which logos data to use
  const logosData = selectedProject === "UX/UI Design" ? uiuxLogos : logogrid;

  return (
    <>
      <div className="h-full bg-[#050303] md:py-16 md:px-28 py-8 px-14 text-white">
        <h2 className="md:text-5xl text-3xl font-normal mb-14 ">
          Our Expertise in {selectedProject}
        </h2>
        <div className="flex md:items-center md:justify-center">
          <div className="grid-item  grid md:grid-cols-3 grid-cols-2 gap-4 md:w-[80%] ">
            {logosData.map((image, index) => (
              <div key={index} className="relative">
                <img
                  src={image.src}
                  alt={""}
                  className=" logo-img w-[300px] h-[253px] "
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {selectedProject === "UX/UI Design" && (
        <div
          className="h-screen md:flex  "
          style={{
            backgroundImage: `url(${contact.contactbg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <img src={services.uiuxextra} alt="" className="md:h-screen h-[400px] w-full pt-14 md:w-2/4" />
          <h2 className=" md:text-7xl text-5xl bg-gradient-to-r from-[#088FC7] to-[#612980] text-transparent bg-clip-text font-extrabold md:pt-40 pt-20 md:pl-20 pl-10">We dream<br/> big with our<br/> Designs</h2>
        </div>
      )}
    </>
  );
};

export default SectionFour;
