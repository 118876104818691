import React, { useEffect, useRef } from 'react';
// import "../CanvasAnimation/canvas.css"
const CanvasAnimation = () => {
    const canvasRef = useRef(null);
    let can_w = 800; // Default canvas width
    let can_h = 800; // Default canvas height

    // let can_w = window.innerWidth; // Default canvas width
    // let can_h = window.innerHeight; // Default canvas height
    console.log("width",can_w);
    let balls = [];
    let mouse_in = false;
    let mouse_ball = {
        x: 0,
        y: 0,
        vx: 0,
        vy: 0,
        r: 0,
        type: 'mouse'
    };
    const ball_color = {
        r: 207,
        g: 255,
        b: 4
    };
    const R = 2;
    const BALL_NUM = 30;
    const alpha_f = 0.03;
    const link_line_width = 0.8;
    const dis_limit = 260;

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        // canvas.width = window.innerWidth;
        // canvas.height = window.innerHeight;
        // can_w = window.innerWidth;
        // can_h = window.innerHeight;
        const getRandomSpeed = (pos) => {
            const min = -1;
            const max = 1;
            switch (pos) {
                case 'top':
                    return [randomNumFrom(min, max), randomNumFrom(0.1, max)];
                case 'right':
                    return [randomNumFrom(min, -0.1), randomNumFrom(min, max)];
                case 'bottom':
                    return [randomNumFrom(min, max), randomNumFrom(min, -0.1)];
                case 'left':
                    return [randomNumFrom(0.1, max), randomNumFrom(min, max)];
                default:
                    return;
            }
        };

        const randomArrayItem = (arr) => {
            return arr[Math.floor(Math.random() * arr.length)];
        };

        const randomNumFrom = (min, max) => {
            return Math.random() * (max - min) + min;
        };

        const getRandomBall = () => {
            const pos = randomArrayItem(['top', 'right', 'bottom', 'left']);
            switch (pos) {
                case 'top':
                    return {
                        x: randomSidePos(can_w),
                        y: -R,
                        vx: getRandomSpeed('top')[0],
                        vy: getRandomSpeed('top')[1],
                        r: R,
                        alpha: 1,
                        phase: randomNumFrom(0, 10)
                    };
                case 'right':
                    return {
                        x: can_w + R,
                        y: randomSidePos(can_h),
                        vx: getRandomSpeed('right')[0],
                        vy: getRandomSpeed('right')[1],
                        r: R,
                        alpha: 1,
                        phase: randomNumFrom(0, 10)
                    };
                case 'bottom':
                    return {
                        x: randomSidePos(can_w),
                        y: can_h + R,
                        vx: getRandomSpeed('bottom')[0],
                        vy: getRandomSpeed('bottom')[1],
                        r: R,
                        alpha: 1,
                        phase: randomNumFrom(0, 10)
                    };
                case 'left':
                    return {
                        x: -R,
                        y: randomSidePos(can_h),
                        vx: getRandomSpeed('left')[0],
                        vy: getRandomSpeed('left')[1],
                        r: R,
                        alpha: 1,
                        phase: randomNumFrom(0, 10)
                    };
                default:
                    return null;
            }
        };

        const randomSidePos = (length) => {
            return Math.ceil(Math.random() * length);
        };

        const renderBalls = () => {
            balls.forEach((b) => {
                if (!b.hasOwnProperty('type')) {
                    ctx.fillStyle = `rgba(${ball_color.r},${ball_color.g},${ball_color.b},${b.alpha})`;
                    ctx.beginPath();
                    ctx.arc(b.x, b.y, R, 0, Math.PI * 2, true);
                    ctx.closePath();
                    ctx.fill();
                }
            });
        };

        const updateBalls = () => {
            const new_balls = [];
            balls.forEach((b) => {
                b.x += b.vx;
                b.y += b.vy;

                if (b.x > -(50) && b.x < (can_w + 50) && b.y > -(50) && b.y < (can_h + 50)) {
                    new_balls.push(b);
                }

                // alpha change
                b.phase += alpha_f;
                b.alpha = Math.abs(Math.cos(b.phase));
            });

            balls = new_balls.slice(0);
        };

        const renderLines = () => {
            let fraction, alpha;
            for (let i = 0; i < balls.length; i++) {
                for (let j = i + 1; j < balls.length; j++) {
                    fraction = getDisOf(balls[i], balls[j]) / dis_limit;

                    if (fraction < 1) {
                        alpha = (1 - fraction).toString();

                        ctx.strokeStyle = `rgba(150,150,150,${alpha})`;
                        ctx.lineWidth = link_line_width;

                        ctx.beginPath();
                        ctx.moveTo(balls[i].x, balls[i].y);
                        ctx.lineTo(balls[j].x, balls[j].y);
                        ctx.stroke();
                        ctx.closePath();
                    }
                }
            }
        };

        const getDisOf = (b1, b2) => {
            const delta_x = Math.abs(b1.x - b2.x);
            const delta_y = Math.abs(b1.y - b2.y);

            return Math.sqrt(delta_x * delta_x + delta_y * delta_y);
        };

        const addBallIfy = () => {
            if (balls.length < BALL_NUM) {
                balls.push(getRandomBall());
            }
        };

        const initBalls = (num) => {
            for (let i = 1; i <= num; i++) {
                balls.push({
                    x: randomSidePos(can_w),
                    y: randomSidePos(can_h),
                    vx: getRandomSpeed('top')[0],
                    vy: getRandomSpeed('top')[1],
                    r: R,
                    alpha: 1,
                    phase: randomNumFrom(0, 10)
                });
            }
        };

        const initCanvas = () => {
            canvas.setAttribute('width', window.innerWidth);
            canvas.setAttribute('height', window.innerHeight);

            can_w = parseInt(canvas.getAttribute('width'));
            can_h = parseInt(canvas.getAttribute('height'));
        };

        const handleMouseEnter = () => {
            mouse_in = true;
            balls.push(mouse_ball);
        };

        const handleMouseLeave = () => {
            mouse_in = false;
            const new_balls = [];
            balls.forEach((b) => {
                if (!b.hasOwnProperty('type')) {
                    new_balls.push(b);
                }
            });
            balls = new_balls.slice(0);
        };

        const handleMouseMove = (e) => {
            const mouseX = e.pageX;
            const mouseY = e.pageY;
            const pyramidWidth = 5; // Adjust this value to change the width of the pyramid
            const pyramidHeight = 5; // Adjust this value to change the height of the pyramid

            const pyramid = [];
            for (let i = 0; i < pyramidHeight; i++) {
                for (let j = 0; j <= i; j++) {
                    const x = mouseX + (j - i / 2) * 2 * R;
                    const y = mouseY + i * Math.sqrt(3) * R;
                    pyramid.push({ x, y });
                }
            }

            balls = [
                ...balls.filter((b) => !b.hasOwnProperty('type')),
                ...pyramid.map((p) => ({ ...mouse_ball, x: p.x, y: p.y }))
            ];
        };

        window.addEventListener('resize', () => {
            initCanvas();
        });

        canvas.addEventListener('mouseenter', handleMouseEnter);
        canvas.addEventListener('mouseleave', handleMouseLeave);
        canvas.addEventListener('mousemove', handleMouseMove);

        const render = () => {
            ctx.clearRect(0, 0, can_w, can_h);
            renderBalls();
            renderLines();
            updateBalls();
            addBallIfy();
            window.requestAnimationFrame(render);
        };

        initCanvas();
        initBalls(BALL_NUM);
        render();

        // Cleanup
        return () => {
            window.removeEventListener('resize', () => {
                initCanvas();
            });
            canvas.removeEventListener('mouseenter', handleMouseEnter);
            canvas.removeEventListener('mouseleave', handleMouseLeave);
            canvas.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return <canvas ref={canvasRef} id="nokey" style={{width:"100%",height:"100%"}}  ></canvas>;
};

export default CanvasAnimation;


