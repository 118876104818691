import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { images } from "../../assets/images";
import { RiArrowRightUpLine } from "react-icons/ri";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className=" md:px-[124px] px-[40px] pb-16 pt-[64px]   bg-[#030303] md:h-[1025px] h-auto w-full">
      <div className="flex md:flex-row  justify-between text-white  pb-10">
        <div className="flex flex-col md:text-[45px]  leading-tight">
          <div className="md:text-[45px] text-[20px]">
            <h1>Have a Project in</h1>
            <h1>Mind ? Let us work</h1>
            <h1>Together</h1>
          </div>
          <div className="md:pt-10 pt-5 flex gap-3">
            <button
              type="button"
              className="text-white md:text-base text-[12px] md:font-extrabold bg-transparent  border border-[#656565] focus:outline-none   rounded-full  md:w-[205px] w-[100px] md:h-[60px] h-[30px]"
              onClick={() => navigate("/projects")}
            >
              View Projects
            </button>
            <a
              href="https://mail.google.com/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <button
                type="button"
                className="text-white relative md:top-[-10px] md:text-base text-[12px] md:font-extrabold bg-gradient-to-r from-[#088FC7] to-[#612980] focus:outline-none rounded-full md:w-[205px] w-[100px] md:h-[60px] h-[30px]"
                onClick={() => navigate("/contactus")}
              >
                Send Email
              </button>
            </a>
          </div>
        </div>
        <div>
          <img
            src={images.footer}
            alt=""
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            className="md:h-auto h-[50px] "
          />
        </div>
      </div>

      <div className="  flex md:flex-row flex-wrap text-xl font-medium text-[#B1A5A5] md:mt-20  gap-3 justify-start ">
        {/* Contact Us Column */}
        <div className=" flex-grow   p-2">
          <h3 className="md:text-2xl text-[18px] font-bold md:pb-11 mb-2">Navigation</h3>
          {/* Navigation Links */}
          <ul className="list-none p-0 text-sm">
            <li className="md:mb-6">
              <Link to="/about">Who We Are</Link>
            </li>
            <li className="md:mb-6">
            <a href="./#services">Services</a>
            </li>
            <li className="md:mb-6">
              <Link to="/projects">Projects</Link>
            </li>
            <li className="md:mb-6">
              <Link to="/careers">Careers</Link>
            </li>
          </ul>
        </div>

        {/* Be in the Know Column */}
        <div className="flex-grow  p-2">
          <h3 className="md:text-2xl text-[18px] font-bold md:pb-11 mb-2">What we do</h3>
          {/* Navigation Links */}
          <ul className="list-none p-0 text-sm">
            <li className="md:mb-6">
              <Link to="/aimlservices">AI/ML Services</Link>
            </li>
            <li className="md:mb-6">
              <Link to="/itservices">Software Development</Link>
            </li>
          </ul>
        </div>

        {/* Menu Column */}
        <div className=" flex-grow p-2">
          <h3 className="md:text-2xl text-[18px] font-bold md:pb-11 mb-2">Support</h3>
          {/* Navigation Links */}
          <ul className="list-none p-0 text-sm">
            <li className="md:mb-6">
              <Link to="/contactus">FAQ</Link>
            </li>
            <li className="md:mb-6">
              <Link to="/contactus">Contact Us</Link>
            </li>
          </ul>
        </div>

        {/* Follow Us On Column */}
        <div className=" flex-grow p-2">
          <h3 className="md:text-2xl text-[18px] font-bold md:pb-11 mb-2">Social</h3>
          {/* Social Media Links */}
          <ul className="list-none p-0 text-sm">
            <li className="md:mb-6">
              <a
                href="https://www.youtube.com/@myresearchroom3076"
                target="_blank"
                rel="noopener noreferrer"
                className="flex gap-1 items-center underline"
              >
                Youtube <RiArrowRightUpLine />
              </a>
            </li>
            <li className="md:mb-6">
              <a
                href="https://www.linkedin.com/company/wesolutize-technologies"
                target="_blank"
                rel="noopener noreferrer"
                className="flex gap-1 items-center underline"
              >
                LinkedIn <RiArrowRightUpLine />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="text-white">
        <div className="h-[87px] items-center md:px-12 rounded-2xl mb-7 text-white md:text-lg font-semibold bg-[#0A0A0A] bg-opacity-50">
          <a href="https://www.wesolutize.com">
            Designed & Developed by{" "}
            <span className="text-[#3A6AFD]"> WeSolutize</span> 
          </a>
          <div>
          <a href="https://www.myresearchroom.com">
            Powered by{" "}
            <span className="text-[#3A6AFD]"> My Research Room</span> 
          </a>
          </div>
        </div>
        <div className="border-t border-white mb-7"></div>
      </div>

      <div className="flex md:flex-row flex-col justify-between text-[#B1A5A5] text-base font-semibold">
        <img
          src={images.logo}
          className="md:h-[51px] h-[45px] w-[120px] md:mb-0 mb-2"
          alt="WeSolutize Logo"
        />
        <h2 className="text-[11px]">@ Copywright WeSolutize. All Rights Reserved.</h2>
      </div>
    </footer>
  );
};
export default Footer;
