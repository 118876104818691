import React from "react";
import { contact } from "../../../assets/contact";

const SectionTwo = () => {
  return (
    <div className="h-full pb-20 flex justify-between bg-[#05111D] text-white">
        <div className="md:w-[60%]  md:pl-28 pl-14">
      <h2 className=" md:text-5xl text-3xl  2xl:text-7xl font-medium pt-10">
      Our Effective & Meticulous<br/> Machine Learning Solutions
      </h2>
      <p className=" text-justify md:pt-16 pt-10 md:w-[90%] w-[80%] md:text-lg text-xl 2xl:text-2xl">
      Businesses worldwide are increasingly integrating Machine Learning solutions to enhance data-driven processes, extract valuable insights for solving intricate business challenges, and enable informed decision-making. ML-based solutions play a pivotal role in automating tasks and processes intelligently.<br/><br/>By leveraging WeSolutize's Machine Learning development services, companies can elevate workforce efficiency, forecast future scenarios, and strive towards groundbreaking achievements.<br/><br/> The Machine Learning solutions offered by WeSolutize span various industries and are underpinned by established computational intelligence, mathematical optimization, and pattern recognition techniques. These solutions empower businesses to make strategic choices, streamline operations, reduce time cycles, and optimize resource utilization effectively.
      </p>
      </div>
      <img src={contact.ml_section2} alt="" className="h-screen hidden md:block  md:w-1/2" />
    </div>
  );
};

export default SectionTwo;
