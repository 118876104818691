// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import About from "./screens/About";
import Services from "./screens/Services";
import ContactUs from "./screens/ContactUs";
import Projects from "./screens/Projects";
import ITServices from "./screens/ITServices";
import AIServices from "./screens/AIServices";
import ViewProject from "./screens/ViewProject";
import Careers from "./screens/Careers";
import AIMLServices from "./screens/AIMLServices";
import Products from "./screens/Products";
import ViewProduct from "./screens/ViewProduct";
import SolarProject from "./screens/SolarProject";
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contactus" element={<ContactUs />} />

        <Route path="/projects" element={<Projects />} />
        <Route path="/products" element={<Products/>} />
        <Route path="/aimlservices" element={<AIMLServices/>} />
        <Route path="/itservices" element={<ITServices />} />
        <Route path="/aiservices" element={<AIServices />} />
        <Route path="/aboutproject" element={<ViewProject />} />
        <Route path="/aboutProduct" element={<ViewProduct/> } />
        <Route path="/careers" element={<Careers />} />
        <Route path="/solarproject" element={<SolarProject/>} />

      </Routes>
    </Router>
  );
};

export default App;
