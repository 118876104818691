import { useMemo } from "react";
import { services } from "../assets/services";
import { useProject } from "../context/selectedServiceContext";

export const ProjectDataGenerator = ({ activeBlock }) => {
  const { selectedProject } = useProject();
 
  const memoizedProjectData = useMemo(() => {
    let projectData = [];

    switch (selectedProject) {
      case "Web Development":
        projectData = [
          {
            id: 1,
            image: activeBlock === 1 ? services.coding : services.coding2,
            image2: services.custom,
            p1: "Our Web Application Development services excel in creating dynamic and responsive websites that adapt flawlessly to all devices, ensuring a seamless user experience across desktops, tablets, and mobile phones. By incorporating flexible grids, layouts, and responsive images, we guarantee that your web application looks and functions impeccably on any screen size or platform.",
            p2: "With our expertise in CSS media queries and cutting-edge design techniques, we deliver websites that not only engage users effectively but also boost your online presence and drive business growth.",
            heading: "Custom Development",
          },
          {
            id: 2,
            image: activeBlock === 2 ? services.external2 : services.external,
            image2: services.webdev,
            p1: "Our Web Application Development services excel in creating dynamic and responsive websites that adapt flawlessly to all devices, ensuring a seamless user experience across desktops, tablets, and mobile phones. By incorporating flexible grids, layouts, and responsive images, we guarantee that your web application looks and functions impeccably on any screen size or platform.",
            p2: "With our expertise in CSS media queries and cutting-edge design techniques, we deliver websites that not only engage users effectively but also boost your online presence and drive business growth.",
            heading: "Web App Development",
          },
          {
            id: 3,
            image: activeBlock === 3 ? services.ol2 : services.ol,
            image2: services.consult,
            p1: "Our Web Application Development services excel in creating dynamic and responsive websites that adapt flawlessly to all devices, ensuring a seamless user experience across desktops, tablets, and mobile phones. By incorporating flexible grids, layouts, and responsive images, we guarantee that your web application looks and functions impeccably on any screen size or platform.",
            p2: "With our expertise in CSS media queries and cutting-edge design techniques, we deliver websites that not only engage users effectively but also boost your online presence and drive business growth.",
            heading: "Web App Consulting",
          },
          {
            id: 4,
            image:
              activeBlock === 4 ? services.responsive2 : services.responsive,
            image2: services.responsive3,
            p1: "Our Web Application Development services excel in creating dynamic and responsive websites that adapt flawlessly to all devices, ensuring a seamless user experience across desktops, tablets, and mobile phones. By incorporating flexible grids, layouts, and responsive images, we guarantee that your web application looks and functions impeccably on any screen size or platform.",
            p2: "With our expertise in CSS media queries and cutting-edge design techniques, we deliver websites that not only engage users effectively but also boost your online presence and drive business growth.",
            heading: "Responsive Design",
          },
          // Add more projects as needed
        ];
        break;
      case "Mobile App Development":
        projectData = [
          {
            id: 1,
            image: activeBlock === 1 ? services.coding : services.coding2,
            image2: services.custom,
            p1: "Our Web Application Development services excel in creating dynamic and responsive websites that adapt flawlessly to all devices, ensuring a seamless user experience across desktops, tablets, and mobile phones. By incorporating flexible grids, layouts, and responsive images, we guarantee that your web application looks and functions impeccably on any screen size or platform.",
            p2: "With our expertise in CSS media queries and cutting-edge design techniques, we deliver websites that not only engage users effectively but also boost your online presence and drive business growth.",
            heading: "Backend Development",
          },
          {
            id: 2,
            image:
              activeBlock === 2 ? services.streamline : services.streamline2,
            image2: services.custom,
            p1: "Our Web Application Development services excel in creating dynamic and responsive websites that adapt flawlessly to all devices, ensuring a seamless user experience across desktops, tablets, and mobile phones. By incorporating flexible grids, layouts, and responsive images, we guarantee that your web application looks and functions impeccably on any screen size or platform.",
            p2: "With our expertise in CSS media queries and cutting-edge design techniques, we deliver websites that not only engage users effectively but also boost your online presence and drive business growth.",
            heading: "Frontend Development",
          },
          {
            id: 3,
            image: activeBlock === 3 ? services.api : services.api2,
            image2: services.custom,
            p1: "Our Web Application Development services excel in creating dynamic and responsive websites that adapt flawlessly to all devices, ensuring a seamless user experience across desktops, tablets, and mobile phones. By incorporating flexible grids, layouts, and responsive images, we guarantee that your web application looks and functions impeccably on any screen size or platform.",
            p2: "With our expertise in CSS media queries and cutting-edge design techniques, we deliver websites that not only engage users effectively but also boost your online presence and drive business growth.",
            heading: "API Development",
          },
          {
            id: 4,
            image: activeBlock === 4 ? services.testing : services.testing2,
            image2: services.custom,
            p1: "Our Web Application Development services excel in creating dynamic and responsive websites that adapt flawlessly to all devices, ensuring a seamless user experience across desktops, tablets, and mobile phones. By incorporating flexible grids, layouts, and responsive images, we guarantee that your web application looks and functions impeccably on any screen size or platform.",
            p2: "With our expertise in CSS media queries and cutting-edge design techniques, we deliver websites that not only engage users effectively but also boost your online presence and drive business growth.",
            heading: "Mobile App Testing",
          },
          // Add more projects as needed
        ];
        break;
      case "UX/UI Design":
        projectData = [
          {
            id: 1,
            image: activeBlock === 1 ? services.ux : services.ux2,
            image2: services.uxdesign,
            p1: "Our Web Application Development services excel in creating dynamic and responsive websites that adapt flawlessly to all devices, ensuring a seamless user experience across desktops, tablets, and mobile phones. By incorporating flexible grids, layouts, and responsive images, we guarantee that your web application looks and functions impeccably on any screen size or platform.",
            p2: "With our expertise in CSS media queries and cutting-edge design techniques, we deliver websites that not only engage users effectively but also boost your online presence and drive business growth.",
            heading: "UX Design",
          },
          {
            id: 2,
            image: activeBlock === 2 ? services.ui : services.ui2,
            image2: services.uidesign,
            p1: "Our Web Application Development services excel in creating dynamic and responsive websites that adapt flawlessly to all devices, ensuring a seamless user experience across desktops, tablets, and mobile phones. By incorporating flexible grids, layouts, and responsive images, we guarantee that your web application looks and functions impeccably on any screen size or platform.",
            p2: "With our expertise in CSS media queries and cutting-edge design techniques, we deliver websites that not only engage users effectively but also boost your online presence and drive business growth.",
            heading: "UI Design",
          },
          {
            id: 3,
            image: activeBlock === 3 ? services.product: services.product2,
            image2: services.roadmap,
            p1: "Our Web Application Development services excel in creating dynamic and responsive websites that adapt flawlessly to all devices, ensuring a seamless user experience across desktops, tablets, and mobile phones. By incorporating flexible grids, layouts, and responsive images, we guarantee that your web application looks and functions impeccably on any screen size or platform.",
            p2: "With our expertise in CSS media queries and cutting-edge design techniques, we deliver websites that not only engage users effectively but also boost your online presence and drive business growth.",
            heading: "Product Roadmap",
          },
          {
            id: 4,
            image: activeBlock === 4 ? services.user : services.user2,
            image2: services.market_research,
            p1: "Our Web Application Development services excel in creating dynamic and responsive websites that adapt flawlessly to all devices, ensuring a seamless user experience across desktops, tablets, and mobile phones. By incorporating flexible grids, layouts, and responsive images, we guarantee that your web application looks and functions impeccably on any screen size or platform.",
            p2: "With our expertise in CSS media queries and cutting-edge design techniques, we deliver websites that not only engage users effectively but also boost your online presence and drive business growth.",
            heading: "Market and User Research",
          },
          // Add more projects as needed
        ];
        break;
      default:
        break;
    }

    return projectData; // Add this return statement
  }, [selectedProject, activeBlock]);
  
  return memoizedProjectData;
};
