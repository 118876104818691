export const testimonials = {
    ashish_vajir: require("./ashish_vajir.png"),
    amol_deshpande: require("./amol_deshpande.png"),
    avinash_magdum: require("./avinash_magdum.png"),
    dipak_patil: require("./dipak_patil.png"),
    ganesh_bhosale: require("./ganesh_bhosale.png"),
    ganesh_joshi: require("./ganesh_joshi_cto.png"),
    omkar_patil: require("./omkar_patil.png"),
    sumedh_joshi: require("./sumedh_joshi.png"),
    sunil_sutar: require("./suni_sutar.png"),

}