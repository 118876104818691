export const projects = {
  erpBackground: require("./erpsolution.png"),
  erpheroimage: require("./ERP Advertise-4.png"),
  erp1: require("./ERP Advertise.png"),
  erp2: require("./ERP Advertise-1.png"),
  erp3: require("./ERP Advertise-3.png"),

  pathoProBackground: require("./pathoProBackground.png"),
  pathoProheroimage: require("./pathoHeroImage.png"),
  pathoPro1: require("./pathoPro1.png"),
  pathoPro2: require("./pathoPro2.png"),
  pathoPro3: require("./pathoPro3.png"),

  elite1: require("./elite_1.png"),
  elite2: require("./elite_2.png"),
  elite3: require("./elite_3.png"),
  elite4: require("./elite_4.png"),
  elite5: require("./elite5.png"),
  elite6: require("./elite6.png"),

  nickol1: require("./nickol6.png"),
  nickol2: require("./nikol2.png"),
  nickol3: require("./nickol3.png"),
  nickol4: require("./nickol4.png"),
  nickol5: require("./nickol5.png"),
  nickol6: require("./nickol3.png"),
  nickol7: require("./nickol1.png"),


  cpm1: require("./cpm1.png"),
  cpm2: require("./cpm2.png"),
  cpm3: require("./cpm3.png"),
  cpm4: require("./cpm4.png"),
  cpm5: require("./cpm5.png"),
  cpm6: require("./cpm6.png"),

  carwash1: require("./carwash1.png"),
  carwash2: require("./carwash2.png"),
  carwash3: require("./carwash3.png"),
  carwash4: require("./carwash4.png"),
  carwash5: require("./carwash5.png"),
  carwash6: require("./carwash6.png"),

  CRM_Bg: require("./CRM_Bg.png"),
  crm_hero_img: require("./crm_hero_img.png"),
  crm1: require("./crm1.jpeg"),
  crm2: require("./crm2.jpeg"),
  crm3: require("./crm3.png"),
};
