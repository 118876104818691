import React from 'react'
import rawSolarImage from "../../../assets/rawImgSolarModel.png"
import AiSolarModel from "../../../assets/AIsolarModel.png"
const SectionFive = () => {
  return (
    <div className='flex flex-col justify-start  items-start bg-black h-full lg:px-28 px-10'>
      <h2 className='text-3xl mt-5 mb-5 font-semibold text-white '>Our Results</h2>
      <div className='flex items-center justify-center lg:w-[90%] w-full mb-10 border border-gray-500 p-2 rounded-xl cursor-pointer'>
        <div className='flex lg:flex-row flex-col justify-center items-center text-white lg:h-[50vh]'>
            <div className='flex flex-col justify-center items-center lg:w-1/2'>
                <img src={rawSolarImage} className='w-[80%]'/>
                <h2 className='mb-2 font-semibold'>Raw Image of Solar Model</h2>
            </div>
            <div className='flex justify-center items-center text-base lg:w-1/2 font-normal col-span-2 z-20 lg:border-l lg:border-t-0 border-t mt-2 lg:px-5'>
                <p>At MRR Innovations, we believe in a future where solar power is not just abundant but also efficient and sustainable. 
                Our AI-driven solutions are a testament to this vision. As we continue to innovate and push the boundaries, 
                we invite stakeholders in the solar industry to join us in this exciting journey towards impeccable solar module maintenance.</p>
            </div>
        </div>
      </div>




      <div className='flex items-center justify-center lg:w-[90%]  mb-10 border border-gray-500 p-2 rounded-xl cursor-pointer'>
        <div className='flex lg:flex-row flex-col lg:justify-center items-center text-white lg:h-[50vh] '>
            <div className='flex flex-col justify-center items-center lg:w-1/2'>
                <img src={AiSolarModel} className='w-[80%]'/>
                <h2 className='mb-2 font-semibold'>AI Detected Image of Solar Module</h2>
            </div>
            <div className='flex flex-col lg:w-1/2 font-normal col-span-2 z-20 lg:border-l lg:border-t-0 border-t mt-2  p-5'>
                <div className='flex flex-row'>
                    <div className='p-2'>
                        <p>Total Cells</p>
                        <p>60</p>
                    </div>
                    <div className='p-2 border-l'>
                        <p>Total Cells</p>
                        <p>60</p>
                    </div>
                    <div className='p-2 border-l'>
                        <p>Total Cells</p>
                        <p>60</p>
                    </div>
                    <div className='p-2 border-l'>
                        <p>Total Cells</p>
                        <p>60</p>
                    </div>
                </div>
                <div className='pt-2 pb-2'>
                <p>Crack cell no</p>
                <p>2, 6, 9, 10, 11, 13, 15, 17, 18, 19, 20, 22, 23, 26, 28, 29, 30, 31, 32, 33, 34, 35, 37, 39, 41, 42, 44, 45, 46, 48, 50, 51, 52, 53, 54, 57, 59</p>

                </div>
                <div className='pt-2 pb-2'>
                    <p>Total Area - 385599.79</p>
                </div>
                <p>Defected Area - 0.004</p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default SectionFive
