export const AIMLServices = [
    {
      title: "Voice Based AI",
      description:
        "Enhance user experience with language based applications for stramlined operations and custom skill development",
    },
    {
      title: "Chatbot developemnt",
      description:
        "Drive customer with intelligent chatbots that autmate tasks and provide instant assistance across industries",
    },
    {
      title: "Image Processing",
      description:
        "Improve efficiency with automated image analysis and recognization using neural networks for text trasactions",
    },
    {
      title: "Predictive analytics",
      description:
        "Utilize statistical algorithms and machine learning for forecasting trends and informed decisions in Predictive Analysis",
    },
    {
      title: "Natural Language Processing",
      description:
        "Extract insights from data sets through text analysis and machine learning algorithms for Natural Language Processing",
    },
    {
      title: "AI Consulting & Integration",
      description:
        "Transform systems with tailored AI solutions for enhanced customer interactions and streamlined processes.",
    },
  ];

  export const SoftwareDevelopmentServices = [
    {
      title: "Web Development",
      description:
        "Elevate your online presence with our expert web development services, where we craft functional, responsive, and user-friendly websites.",
    },
    {
      title: "Devops Services",
      description:
        "Streamline your software development processes and enhance efficiency with our DevOps services, ensuring faster delivery.",
    },
    {
      title: "UI/UX Design",
      description:
        "Transform user experiences with our expert UX/UI design services, creating visually appealing and intuitive interfaces.",
    },
    {
      title: "Mobile App Development",
      description:
        "Engage your audience with cutting-edge mobile applications designed and developed by our skilled team to meet your specific requirements.",
    },
    {
      title: "Software Maintenance",
      description:
        "Ensure the longevity and efficiency of your digital solutions with our comprehensive software maintenance services.",
    },
    {
      title: "Cloud Based Services",
      description:
        "Embrace the power of cloud computing with our sophisticated solutions that offer scalability, accessibility, and advanced customization.",
    },
  ];

  // Define your data for AI/ML and Software Development
  export const aimlData = {
    title: "Machine Learning Model Development",
    description: "Offering comprehensive services from data analysis to model deployment, we specialize in developing robust machine learning models for accurate predictions and optimized business outcomes."
  };

  export const softwareDevData = {
    title:"Custom Software Development",
    description: "Deliver tailored software solutions to optimize business operations, from user-friendly websites and cutting-edge mobile apps to comprehensive support services and efficient cloud solutions."
  };