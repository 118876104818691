import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import jobData from "./jobdata";
import { Link } from "react-router-dom";

const Modal = ({ isOpen, onClose, title }) => {
  if (!isOpen || !jobData[title]) {
    // If the modal is not open or data for the selected title does not exist
    return null;
  }

  const { responsibilities, qualifications,duration,location, experience } = jobData[title];

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 p-5">
      <div className="absolute inset-0 bg-gray-900 opacity-50 "></div>
      <div className="bg-white rounded-lg shadow-lg p-8 h-[450px] z-10 overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="md:text-lg font-semibold">{title}</h2>
          <IoCloseOutline className="cursor-pointer" onClick={onClose} />
        </div>
        <p className="mb-2 md:text-none text-[16px]">{location}</p>

        <div style={{ marginBottom: "20px" }}>
          <h2 className="md:text-lg text-blue-700 font-semibold">Responsibilities:</h2>
          <ul className="md:text-xs text-sm" style={{ listStyleType: "none", padding: 0 }}>
            {responsibilities.map((responsibility, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>{responsibility}</li>
            ))}
          </ul>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <h2 className="md:text-lg font-semibold text-blue-700">Qualifications:</h2>
          <ul className="md:text-xs text-sm" style={{ listStyleType: "none", padding: 0 }}>
            {qualifications.map((qualification, index) => (
              <li key={index} style={{ marginBottom: "10px" }}>{qualification}</li>
            ))}
          </ul>
        </div>
        {(title === "MERN Stack Intern" || title === "AI ML Intern") && (
          <p className="md:text-xs text-sm font-semibold">Internship Duration: {duration}</p>
        )}
        <p className="md:text-xs text-sm font-semibold">Location: {location}</p>
        {(title !== "MERN Stack Intern" && title !== "AI ML Intern") && (
        <p className="md:text-xs text-sm font-semibold">Experience: {experience}</p>
        )}

        {(title === "MERN Stack Intern" || title === "AI ML Intern" || title === 'UI/UX Designer') && (
        <a
          href={title === 'UI/UX Designer' ? "https://forms.gle/67yDk8RAH8CtXLKu5" : "https://forms.gle/r8gsRD6fe8Z5PDcr9"}
          target="_blank"
          rel="noopener noreferrer"
          className="px-4 py-2 bg-blue-500 md:h-[38px] md:w-[126px] w-[100px] h-[25px] text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          style={{
            marginTop: "20px",
            left: "24px",
            padding: "10px",
            gap: "10px",
            borderRadius: "28px",
            background: "linear-gradient(90.16deg, #088FC7 3.4%, #612980 94.2%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              fontFamily: "Mulish",
              fontWeight: 600,
              lineHeight: "17.57px",
              textAlign: "center",
              color: "#FFFFFF",
            }}
            className="md:text-sm text-[12px]"
          >
            Apply Now
          </span>
        </a>
        )}
      </div>
    </div>
  );
};

export default Modal;