import React from "react";
import { useProject } from "../../../context/selectedServiceContext";
import { viewProductsData } from "../../../data/viewproductsdata";
import { Navigate } from "react-router-dom";

const DataSection = () => {
  const { selectedProduct } = useProject(); // Access the selected project from the context
  console.log("product:", selectedProduct);


  // Filter the viewProjectsData array to find the selected project
  const selectedProductData = viewProductsData.find(
    (product) => product.id === selectedProduct
  );

   if (!selectedProduct) {
    return <Navigate to="/" />;
}

  return (
    <div className="md:h-screen   pb-20 pt-10 md:px-28 px-14 md:flex gap-14  bg-black text-white">
      <div className="md:w-[30%] md:block hidden  ">
        <div className=" h-fit bg-[#16161B] border rounded-3xl border-[#656565] pb-6 px-6">
          {/* First Row */}
          <div className=" justify-between pt-6 items-center mb-5">
            {/* Heading */}
            <h2 className="md:text-xl text-[16px] font-bold">Client</h2>
            {/* Sub-paragraph */}
            <p className="md:text-lg font-normal">{selectedProductData.client}</p>
          </div>
          {/* Separator */}
          <hr className="border-gray-400 mb-5" />

          {/* Second Row */}
          <div className=" justify-between items-center mb-5">
            {/* Heading */}
            <h2 className="md:text-xl text-[16px] font-bold">Services</h2>
            {/* List of items */}
            <ul className="md:text-lg font-normal">
              {selectedProductData.services.map((service, index) => (
                <li key={index}>{service}</li>
              ))}
            </ul>
          </div>
          {/* Separator */}
          <hr className="border-gray-400 mb-5 " />

          {/* Third Row */}
          <div className=" justify-between  items-center">
            {/* Heading */}
            <h2 className="md:text-xl text-[16px] font-bold">Industry</h2>
            {/* Sub-paragraph */}
            <p className="md:text-lg font-normal">
              {selectedProductData.industry}
            </p>
          </div>
        </div>
        <button
          type="button"
          class="text-white  text-base font-extrabold bg-[#121216]  border border-[#656565] focus:outline-none mt-7 md:mb-0 mb-4  rounded-full w-full  md:h-[60px] h-[40px]"
        >
          Start a Conversation
        </button>
      </div>

      <div className="md:w-[70%] p-2 md:overflow-y-scroll overflow-x-visible	no-scrollbar	  	">
        <h2 className=" md:text-2xl text-xl  2xl:text-5xl font-extrabold ">The Brief</h2>
        <p className=" text-justify pt-6 md:text-xl text-sm font-medium 2xl:text-2xl">
          {selectedProductData.brief}
        </p>
        <div className="md:flex justify-between gap-2  h-fit mt-6">
          <img src={selectedProductData.subimg1} alt="" className="md:w-1/2 md:mb-0 mb-5 " />
          <img src={selectedProductData.subimg2} alt="" className="md:w-1/2 " />
        </div>
        <div className="mt-12">
          <h2 className=" md:text-2xl text-xl  2xl:text-5xl font-extrabold ">
            The Challenge
          </h2>
          <p className=" text-justify pt-6 md:text-xl text-sm font-medium 2xl:text-2xl">
            {selectedProductData.challenge}
          </p>
        </div>
        <div className="mt-12">
          <h2 className=" md:text-2xl text-xl  2xl:text-5xl font-extrabold ">
            The Solution
          </h2>
          <p className=" text-justify pt-6 md:text-xl text-sm font-medium 2xl:text-2xl">
            {selectedProductData.solution}
          </p>
        </div>
        <img src={selectedProductData.subimg3} alt="" className="mt-6" />
        <img src={selectedProductData.subimg4} alt="" className="mt-6"/>
        <div className="mt-9">
          <h2 className=" md:text-2xl text-xl  2xl:text-5xl font-extrabold ">
            Our Working Process
          </h2>
          <p className=" text-justify pt-6 md:text-xl text-sm font-medium 2xl:text-2xl">
            {selectedProductData.workingProcess}
          </p>
        </div>
        <div className="mt-9 grid md:grid-cols-2 grid-cols-1 gap-4  text-black">
          <div className="bg-[#E1E1E1] rounded-2xl py-5 md:px-6 px-3 col-span-1">
            <h2 className="md:text-2xl text-xl  font-normal mb-5">Multiple Food Products</h2>
            <p className="text-base font-normal text-[#000422]">
                Versatile drying capabilities cater to a variety of food products, accommodating diverse drying requirements.
            </p>
          </div>
          <div className="bg-[#E1E1E1] rounded-2xl py-5 px-6 col-span-1">
            <h2 className="md:text-2xl text-xl  font-normal mb-5">Iot based</h2>
            <p className="text-base font-normal text-[#000422]">
                Leveraging IoT connectivity allows remote monitoring and control of the drying process, enhancing efficiency.{" "}
            </p>
          </div>
          <div className="bg-[#E1E1E1] rounded-2xl py-5 px-6 col-span-1">
            <h2 className="md:text-2xl text-xl  font-normal mb-5">Increased Shell Life</h2>
            <p className="text-base font-normal text-[#000422]">
                Extends the shelf life of dried food products by ensuring optimal drying conditions and quality preservation.{" "}
            </p>
          </div>
          <div className="bg-[#E1E1E1] rounded-2xl py-5 px-6 col-span-1">
            <h2 className="md:text-2xl text-xl  font-normal mb-5">Low Cost</h2>
            <p className="text-base font-normal text-[#000422]">
                Cost-effective drying solution utilizing solar energy and IoT connectivity, reducing operational expenses.{" "}
            </p>
          </div>

          <div className="bg-[#E1E1E1] rounded-2xl py-5 px-6 col-span-1">
            <h2 className="md:text-2xl text-xl  font-normal mb-5">Farmer’s Friend</h2>
            <p className="text-base font-normal text-[#000422]">
                Supports farmers by offering a user-friendly and automated drying solution, simplifying the drying process.{" "}
            </p>
          </div>

          <div className="bg-[#E1E1E1] rounded-2xl py-5 px-6 col-span-1">
            <h2 className="md:text-2xl text-xl  font-normal mb-5">Quality Assurance</h2>
            <p className="text-base font-normal text-[#000422]">
                Ensures optimal drying conditions through IoT monitoring, guaranteeing high-quality dried food products.{" "}
            </p>
          </div>
        </div>
        <button
          type="button"
          class="text-white md:hidden  block text-base font-extrabold bg-[#121216]  border border-[#656565] focus:outline-none mt-7 md:mb-0 mb-4  rounded-full w-full  md:h-[60px] h-[40px]"
        >
          Start a Conversation
        </button>
      </div>
    </div>
  );
};

export default DataSection;
