export const navbar = {
    ml: require("./MachinLearning.png"),
    chatbot: require("./ChatbotDevelopment.png"),
    image_process: require("./ImageProcessing.png"),
    data_mine: require("./DataMining.png"),
    predictive: require("./PredictiveAnalysis.png"),
    voice_ai: require("./VoiceBasedAI.png"),
    web_dev: require("./WebDevelopment.png"),
    devops: require("./DevopsServices.png"),
    uiux: require("./UIUXServices.png"),
    mobile: require("./MobileAppDevelopment.png"),
    maintainence: require("./SoftwareMaintance.png"),
    cloud: require("./cloudBasedServices.png"),
   bg: require("./bg.png")

  
  };
  