import React from "react";
import { useNavigate } from "react-router-dom";

const HelpSection = () => {
  const navigate= useNavigate();
  return (
    <div className="md:h-full  bg-cover md:py-16 py-10 md:px-28 px-14 font-medium md:text-5xl text-2xl md:flex  items-center justify-center  bg-gradient-to-r from-[#088FC7] to-[#612980]">
      <div className="md:w-3/4 text-white md:mb-0 mb-10">
        <h2 >
          Want to Get Top-notch AI and <br />
          ML Services?
        </h2>
      </div>

      <div className="md:w-1/4 flex md:flex-col justify-center items-center gap-6">
        <button className=" text-white flex justify-center items-center md:text-xl text-[12px] font-semibold md:w-[205px] w-[170px] md:h-[60px] h-[35px] py-2 md:px-4 rounded-full border-white border" onClick={()=>navigate("/contactus")}>
          Know More
        </button>
        <button className="bg-gradient-to-r from-[#088FC7] to-[#612980] text-white md:text-xl text-[12px] flex justify-center items-center font-semibold md:w-[205px] w-[170px] md:h-[60px] h-[35px]  py-2 md:px-4 rounded-full " onClick={()=>navigate("/contactus")}>
          Schedule a call
        </button>
      </div>
    </div>
  );
};

export default HelpSection;
