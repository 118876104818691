import { projects } from "../assets/projects/projects";

export const projectData = [
    {
      id: 2,
      title: "Car Wash App",
      category: "Mobile App Development",
      description: "Experience the Future of Car Washing App",
      image: projects.carwash6,
    },
    {
      id: 1,
      title: "Elite Engineering Project",
      category: "Website Redesign",
      description: "Explore our Dynamic website for Elite Engineering Project",
      image: projects.elite6,
    },
    {
      id: 3,
      title: "Whizkey Solutions",
      category: "Mobile App Development",
      description: "Discovered the IOT-based app for tailored Whizkey Solutions",
      image: projects.cpm6,
    },
    // Add more projects as needed
  ];