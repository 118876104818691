export const about = {
  hero: require("./about_hero.png"),
  hero2: require("./Business Discussion.png"),
  howitstarted: require("./HowItStarted.png"),
  img1: require("./img1.png"),
  logo: require("./logo3d_1.png"),
  who_we_are: require("./who_we_are.png"),
  background: require("./backgroud.png"),
  leadership: require("./leadership.png"),
  itservices: require("./itservices.png")
};
