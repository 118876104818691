import React, { useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { images } from "../../assets/images";
import { FaAngleDown } from "react-icons/fa";
import { navbar } from "../../assets/navbar";
import { useProject } from "../../context/selectedServiceContext";
import "./index.css"
const Header = () => {
  const navigate = useNavigate();
  const { setProject } = useProject();
  const location = useLocation();

  const [isServicesModalOpen, setIsServicesModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleServicesModal = () => {
    setIsServicesModalOpen(!isServicesModalOpen);
  };

  const ServicesModal=()=>{
    setIsOpen(!isOpen);
  }

  const handleContactUs = () => {
    navigate("/contactus");
  };

  const handleITServicesClick = () => {
    navigate("/itservices");
  };

  const handleAIMLServicesClick = () => {
    navigate("/aimlservices");
  };

  const AIMLServices = [
    {
      id: 1,
      image: navbar.ml,
      title: "Machine Learning",
      path: "/aiservices",
      description: "All about how to use",
    },
    {
      id: 2,
      image: navbar.chatbot,
      title: "Chatbot Development",
      path: "/aiservices",
      description: "Newest feature Releases",
    },
    {
      id: 3,
      image: navbar.image_process,
      title: "Image Processing",
      path: "/aiservices",
      description: "Newest Feature Releases",
    },
    {
      id: 4,
      image: navbar.data_mine,
      title: "Data Mining",
      path: "/aiservices",
      description: "Questions and tips from others",
    },
    {
      id: 5,
      image: navbar.predictive,
      title: "Predictive Analysis",
      path: "/aiservices",
      description: "Dynamic Growth and Discovery",
    },
    {
      id: 6,
      image: navbar.voice_ai,
      title: "Voice based AI",
      path: "/aiservices",
      description: "Questions and Tips from others",
    },
  ];

  const SoftwareDevelopmentServices = [
    {
      id: 1,
      image: navbar.web_dev,
      title: "Web Development",
      path: "/services",
      description: "Everything you need to know",
    },
    {
      id: 2,
      image: navbar.devops,
      title: "Devops Services",
      path: "/itservices",
      description: "How our products help you",
    },
    {
      id: 3,
      image: navbar.uiux,
      title: "UX/UI Design",
      path: "/services",
      description: "Building Products and Systems",
    },
    {
      id: 4,
      image: navbar.mobile,
      title: "Mobile App Development",
      path: "/services",
      description: "Discovers books and Webinars",
    },
    {
      id: 5,
      image: navbar.maintainence,
      title: "Software Maintaince",
      path: "/itservices",
      description: "Discover Books and Webinars",
    },
    {
      id: 6,
      image: navbar.cloud,
      title: "Cloud Based Services",
      path: "/itservices",
      description: "Discover Books and Webinars",
    },
  ];

  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };


  const handleButtonClick = (path, title) => {
    if (
      ["Web Development", "Mobile App Development", "UX/UI Design"].includes(title)) {
      setProject(title);
      console.log("title", title);
      navigate(path);
      toggleServicesModal();
    }else {
      navigate(path);
      toggleServicesModal();

    }
  };

  return (
    <header className="sticky top-0 md:left-0  right-0 z-50 flex justify-between items-center px-6  bg-black h-[60px]  md:h-[100px] w-[100%]">
      <div className="logo-container md:w-[15%] ">
        <a href="/">
          <img
            src={images.logo}
            alt="Logo"
            className="logo h-[50px] w-[120px]  md:w-full md:h-[92px] "
          />
        </a>
      </div>

      <nav className={`text-base font-extrabold   flex justify-between items-center   md:h-full md:w-[80%]  ${showMenu ? 'slideInLeft' : 'slideOutLeft'}`}>
      

        <ul className={`md:flex hidden text-white justify-between items-center w-[60%] h-full relative right-10 md:ml-auto  gap-5  `}>
          <li className={`text-white text-base  font-extrabold ${isActive('/about')?'active':''}`}><a href="/about">Who We Are</a></li>
          <li className={`mr-4 text-white text-base font-extrabold focus:outline-none  cursor-pointer ${isServicesModalOpen?'active':''} `} onClick={toggleServicesModal}> Service <i class="fa-solid fa-angle-down font-extrabold"></i></li>
          <li className={`text-white text-base font-extrabold ${isActive('/projects')?'active':''} `}><a href="/projects">Project</a></li>
          <li className={`text-white text-base font-extrabold ${isActive('/products')?'active':''} `}><a href="/products">Products</a></li>
          <li className={`text-white text-base font-extrabold ${isActive('/careers')?'active':''}`}><a href="/careers">Careers</a></li>
          {/* <li className={`contact-button ${isActive('/contactus')?'active':''}`}><a href="/contactus" onClick={handleContactUs}>Contact</a></li> */}
        </ul>

        <div className="md:flex hidden bg-gradient-to-r from-[#088FC7] to-[#612980] text-center text-white text-xl justify-center font-semibold p-3  rounded-full w-[130px]">
          <a href="/contactus" onClick={handleContactUs} >Contact Us</a>
        </div>
      </nav>


        {/* ******  For Small Screen ******* */}
        {/* bg-[#342d71]  */}
        {/* relative top-[420px] left-9 w-[100%] */}
      { 
        showMenu && (
          <div className={`md:hidden absolute top-[60px] w-[100%] -left-[2px] flex flex-col  h-[100vh] bg-black  md:bg-none ${showMenu ? 'animate-slideRightToLeft' : 'animate-slideLeftToRight'}`}>
            <ul className={`flex flex-col text-center justify-center relative   md:hidden text-white mt-10  gap-5 `}>
              <li className={`text-white text-base font-semibold   ${isActive('/about')?'active':''}`}><a href="/about">Who We Are</a></li>
              <li className={`text-white text-base font-semibold  ${isOpen?'active':''}`} onClick={ServicesModal}>Service <i class="fa-solid fa-angle-down font-semibold"></i></li>

              {
                isOpen && (
                  <ul className="relative">
                      <li className="text-white text-base font-semibold mb-4" onClick={handleAIMLServicesClick}>Ai Ml Services</li>
                      <li className="mr-4 text-white text-base mb-4  font-semibold focus:outline-none cursor-pointer " onClick={handleITServicesClick}>Software Development Services</li>
                  </ul>
                )
              }

              <li className={`text-white text-base font-semibold  ${isActive('/projects')?'active':''}`}><a href="/projects">Project</a></li>
              <li className={`text-white text-base font-semibold  ${isActive('/products')?'active':''}`}><a href="/products">Products</a></li>
              <li className={`text-white text-base font-semibold  ${isActive('/careers')?'active':''}`}><a href="/careers">Career</a></li>
              {/* <li className={`text-white text-base font-semibold  ${isActive('/contactus')?'active':''}`}><a href="/contactus">Contact</a></li> */}
            </ul>
            <div className="md:hidden flex justify-center items-center  text-center text-white text-base mt-4 font-semibold  w-full">
              <a href="/contactus" onClick={handleContactUs} className="w-[150px] bg-gradient-to-r from-[#088FC7] to-[#612980] p-2 rounded-full">Contact Us</a>
            </div>
          </div>
        )
      }

      {/* ************ Menu Icon **************** */}
      <div className="md:hidden ">
        <button className="text-white" onClick={toggleMenu}>
          {showMenu ? (
            <i className="fas fa-times"></i> // Assuming you're using Font Awesome for icons
          ) : (
            <i className="fas fa-bars"></i>
          )}
        </button>
      </div>
      

      {/* Services Modal */}
      {isServicesModalOpen && (
        <div className=" fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-[#252525] bg-opacity-50 cursor-pointer" onClick={toggleServicesModal}>
          <div className="bg-white w-[60%] h-[70%]  p-4  rounded-md shadow-md flex flex-row md:flex-col flex-wrap items-center justify-center gap-4 overflow-y-auto" style={{scrollbarColor:"transparent transparent",scrollbarWidth:"thin"}}>
            {/* AIML Services */}
            <div className="flex flex-col md:w-1/2  h-[100%] overflow-y-auto" style={{scrollbarWidth:"thin"}}>
              <h2 className="text-sm font-normal tracking-normal mb-4    bg-gradient-to-r from-[#088FC7] to-[#612980] text-transparent bg-clip-text">
                AI/ML SERVICES
              </h2>
              {AIMLServices.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-row items-center justify-between  "
                >
                  <div className="flex flex-row justify-between gap-1">
                    {/* Display in col */}
                    <div className="flex flex-col p-1 w-fit   ">
                      <div className="flex flex-row justify-start gap-4">
                        <img
                          src={item.image}
                          className="h-6 w-6"
                          alt={item.title}
                        />
                        <button onClick={() => handleButtonClick(item.path,item.title)}>
                          <h2 className="text-base  font-normal text-[#111B29]">
                            {item.title}
                          </h2>
                        </button>
                      </div>
                      <div className="pl-10">
                        <p className="text-sm font-normal text-[#677489]">{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <button
                onClick={handleAIMLServicesClick}
                className=" bg-gradient-to-r from-[#088FC7] to-[#612980] text-transparent bg-clip-text px-4 py-1 rounded-md mt-5 text-sm text-left font-normal"
              >
               VIEW ALL
              </button>
            </div>

            {/* Software Development Services */}
            <div className="flex flex-col md:w-1/2  h-[100%]">
              <h2 className="text-sm font-normal tracking-normal mb-4 bg-gradient-to-r from-[#088FC7] to-[#612980] text-transparent bg-clip-text">
                SOFTWARE DEVELOPMENT SERVICES
              </h2>
              {SoftwareDevelopmentServices.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-row items-center justify-between"
                >
                  <div className="flex flex-row justify-between gap-1">
                    {/* Display in col */}
                    <div className="flex flex-col p-1 w-fit ">
                    <div className="flex flex-row justify-start gap-4">
                        <img
                          src={item.image}
                          className="h-6 w-6"
                          alt={item.title}
                        />
                         
                        <button onClick={() => handleButtonClick(item.path,item.title)}>
                        <h2 className="text-base  font-normal text-[#111B29]">
                            {item.title}
                          </h2>
                        </button>
                      </div>
                      <div className="pl-10">
                      <p className="text-sm font-normal text-[#677489]">{item.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <button
                onClick={handleITServicesClick}
                className=" bg-gradient-to-r from-[#088FC7] to-[#612980] text-transparent bg-clip-text px-4 py-1 rounded-md mt-5 text-sm text-left font-normal"
              >
               VIEW ALL
              </button>
            </div>
          </div>
        </div>
      )}

      
    </header>
  );
};

export default Header;
