const logo = {
  godaddy: require("./Godaddy.png"),
  hostinger: require("./Hostinger.png"),
  aws: require("./aws.png"),
  google_cloud: require("./GoogleCloud.png"),
  cloudinary: require("./Cloudinary.png"),
  mongodb: require("./Mongo.png"),
  leapswitch: require("./LeapSwitch.png"),
  onhost: require("./OnHosting.png"),
  node:require("./node.png"),
  react:require("./react.png"),
  sql:require("./sql.png"),
  jquery:require("./jquery.png"),
  vs:require("./vs.png"),
  tailwind:require("./tailwind.png"),
  html:require("./html.png"),
  css:require("./css.png"),
  bootstrap:require("./bootstrap.png"),
  figma:require("./figma.png"),
  photoshop:require("./photoshop.png"),
  illustrator:require("./aiillustrator.png"),
  react_native:require("./React_Native.png"),
};

export default logo;
