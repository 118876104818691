import React from "react";
import { about } from "../../../assets/about";

const WhoWeAre = () => {
  return (
    <div
      className="relative flex flex-col items-center justify-center"
      style={{
        backgroundImage: `url(${about.who_we_are})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
     

      {/* First Overlay */}
      <div className="flex-grow items-center mt-16 justify-center   bg-[#252525]  bg-opacity-50 rounded-3xl  w-[80%]">
        <div className="text-white items-center justify-center py-4  md:px-8 px-4 md:grid md:grid-cols-3 ">
          <h2 className="text-2xl font-semibold mb-4 md:mr-40 ">Who Are We</h2>
          <div className="text-base  font-normal col-span-2 md:px-12 z-20  md:pr-20 md:border-l-4 md:border-t-0 border-t-4">
            <p className="md:mt-0 mt-2">
              WeSolutize is a software consulting company that specializes  
              in software development and AI/ML.
            </p>
            <br />
            <p>
              The team at WeSolutize consists of innovators, designers,
              and engineers who are passionate about developing products using
              cutting-edge technologies.
            </p>
            <br />
            <p>
              The name "WeSolutize" signifies intelligent innovation,
              reflecting the team's multi-talented engineers who enjoy playing
              with innovation.
            </p>
          </div>
        </div>
      </div>

      {/* Second Overlay */}
      <div className=" flex-grow items-center justify-center mt-16 mb-16  bg-[#252525] bg-opacity-50 rounded-3xl w-[80%]">
        <div className="text-white md:grid md:grid-cols-3 gap-4 items-center justify-center py-4 md:px-8 px-4">
          <h2 className="text-2xl font-semibold mb-4 md:mr-40">How We Work</h2>
          <div className="text-base font-normal text-white  col-span-2 md:px-10 md:border-l-4 md:border-t-0 border-t-4">
            <p className="md:mt-0 mt-4">
              <p className="text-lg font-medium mb-2">Think Big</p>
              We apply design thinking, business strategy, and technology
              excellence to create solutions and opportunities for our clients.
            </p>
            <p className="md:mt-0 mt-4">
              <p className="text-lg font-medium mb-2">Deliver Value</p>
              Build and measure what matters. Trendy is good, but we need our
              solutions to be viable business solutions as well.
            </p>
            <p className="md:mt-0 mt-4">
              <p className="text-lg font-medium mb-2">Move Fast</p>
              
              Agile culture to get you where you need to go quickly. Detailed
              planning, accurate design, and faster deliveries.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
