import React,{useState,useEffect} from "react";
import { home } from "../../../assets/home";
import { useNavigate } from "react-router-dom";
import CanvasAnimation from "../CanvasAnimation/CanvasAnimation";
import "../CanvasAnimation/Style.css"

import Modal from "./Modal";

const HeroSections = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    const canvas = document.getElementById('canvas');
    const ctx = canvas.getContext('2d');  
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    let spots = [];
    let hue = 0;
    const mouse = {
        x: undefined,
        y: undefined
    };

    canvas.addEventListener('mousemove', function(event) {
        mouse.x = event.x;
        mouse.y = event.y;

        for (let i = 0; i < 3; i++) {
            spots.push(new Particle());
        }
    });

    class Particle {
        constructor() {
            this.x = mouse.x;
            this.y = mouse.y;
            this.size = Math.random() * 2 + 0.1;
            this.speedX = Math.random() * 2 - 1;
            this.speedY = Math.random() * 2 - 1;
            this.color = 'hsl(' + hue + ', 100%, 50%)';
        }
        update() {
            this.x += this.speedX;
            this.y += this.speedY;
            if (this.size > 0.1) this.size -= 0.03;
        }
        draw() {
            ctx.fillStyle = this.color;
            ctx.beginPath();
            ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
            ctx.fill();
        }
    }

    function handleParticles() {
        for (let i = 0; i < spots.length; i++) {
            spots[i].update();
            spots[i].draw();
            for (let j = i; j < spots.length; j++) {
                const dx = spots[i].x - spots[j].x;
                const dy = spots[i].y - spots[j].y;
                const distance = Math.sqrt(dx * dx + dy * dy);
                if (distance < 90) {
                    ctx.beginPath();
                    ctx.strokeStyle = spots[i].color;
                    ctx.lineWidth = spots[i].size / 10;
                    ctx.moveTo(spots[i].x, spots[i].y);
                    ctx.lineTo(spots[j].x, spots[j].y);
                    ctx.stroke();
                }
            }
            if (spots[i].size <= 0.3) {
                spots.splice(i, 1);
                i--;
            }
        }
    }

    function animate() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        handleParticles();
        hue++;
        requestAnimationFrame(animate);
    }

    window.addEventListener('resize', function() {
        canvas.width = this.innerWidth;
        canvas.height = this.innerHeight;
    });

    window.addEventListener('mouseout', function() {
        mouse.x = undefined;
        mouse.y = undefined;
    });

    animate();

    return () => {
      window.removeEventListener('resize', function() {
        canvas.width = this.innerWidth;
        canvas.height = this.innerHeight;
      });

      window.removeEventListener('mouseout', function() {
        mouse.x = undefined;
        mouse.y = undefined;
      });
    };
  }, []);

  const [open,setOpen]=useState(false)
  useEffect(() => {
    // Check if modal has already been shown in the current session
    const modalShown = sessionStorage.getItem("modalShown");
    if (!modalShown) {
      setOpen(true);
      sessionStorage.setItem("modalShown", "true");

      const timeout = setTimeout(() => {
        setOpen(false);
      }, 60000);
    }
  }, []);

  return (
    <>
      {/* <canvas id="canvas" ></canvas> */}

      {open && <Modal setOpen={setOpen} />}
      <div
      className="h-screen relative  bg-black text-white"
      // style={{
      //   backgroundImage: `url(${home.hero})`,
      //   backgroundSize: "cover",
      //   backgroundPosition: "center",
      // }}
    >
    <div className="absolute inset-0 z-5">
    <CanvasAnimation></CanvasAnimation>
        <canvas id="canvas" ></canvas>
      </div>
      <div className="md:pl-28 px-14  pt-28 ">
      {/* lg:absolute top-0   */}
        <h2 className="md:text-6xl  text-3xl font-semibold pb-4">Exploring the Digital</h2>
        <h2 className="md:text-6xl text-2xl font-semibold">Transformation Services</h2>
        <h2 className="md:text-xl md:w-[60%] text-[16px] pt-5 font-normal text-justify">
          Embarking on a transformative journey of innovation and exploration
          within
          IT consulting services, we delve into cutting-edge technologies
          and pioneering
           solutions.
        </h2>
       
      </div>
      <div className="absolute z-20 md:pl-28 px-14 pb-60">
      <button
          className="bg-gradient-to-r from-[#088FC7] to-[#612980]    cursor-pointer text-white flex justify-center items-center text-xl font-semibold mt-10 md:w-[204px] w-[180px] md:h-[60px] h-[50px]  p-4 rounded-full "
          onClick={()=>{navigate("/projects")}}
          >
          See Projects
        </button>
      </div>
    </div>
    </>
  );
};

export default HeroSections;
