import React from 'react'
import { homelogo } from '../../../data/logogrid'

const SectionSix = () => {
  return (
    <>
    <div className="md:h-full h-auto bg-[#050303] py-16 md:px-28 px-[40px] text-white">
      <h2 className="md:text-5xl text-2xl font-normal mb-14 ">
      Elevating Business Value<br/> Through Strategic Partnerships      </h2>
      <div className="flex items-center justify-center">
        <div className="grid-item  grid md:grid-cols-3 grid-cols-2 gap-4 md:w-[80%] ">
          {homelogo.map((image, index) => (
            <div key={index} className="relative">
              <img
                src={image.src}
                alt={""}
                className=" logo-img md:w-[300px]  h-[253px] "
              />
            </div>
          ))}
        </div>
      </div>
    </div>
    
  </>  )
}

export default SectionSix