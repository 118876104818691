import { products } from "../assets/products/products";

// Sample project data
export const viewProductsData = [
  {
    id: 1,
    image: products.solarModule,
    heading: "Comprehensive Solar Module Maintenance",
    text: "Discover how our cutting-edge maintenance services can safeguard your solar modules, ensuring peak performance and longevity. Say goodbye to revenue losses and high maintenance costs - invest in efficiency and reliability today",
    conversionRate: 26,
    rating: 4.5,
    // tagline1: "Explore Our Dynamic Website Design for",
    // tagline2: "Elite Engineering",
    // heroimg: projects.elite1,
    // client:"Elite Engineering",
    // services: ["UX/UI Design", "Web Development", "SEO Optimization"],
    // industry:"Engineering Consultation",
    // brief:"We revamped an engineering consulting website, integrating an admin panel for clients to effortlessly add projects. This enhancement aims to streamline project management and showcase client work efficiently.",
    // subimg1: projects.elite3,
    // subimg2: projects.elite4,
    // challenge: "In enhancing the engineering consulting website with an admin panel for client project management, our focus was on creating a user-friendly interface that caters to clients with diverse technical backgrounds. Ensuring data security was paramount, leading us to implement robust measures for secure data handling and access control within the admin panel. Seamless integration with the existing website architecture was achieved through careful planning and API utilization, guaranteeing a cohesive and efficient system for clients to add and manage projects with ease and confidence.",
    // solution: "In the redesign of the engineering consulting website and the implementation of an admin panel for client project management, our approach centered on user-centric design by emphasizing a clean and intuitive interface for effortless project uploads. Security was a top priority, leading to the implementation of role-based access control to protect client data effectively. Through strategic API integration, we seamlessly connected the admin panel with the website backend, ensuring smooth data transfer and enhancing overall system efficiency for streamlined project management and enhanced data security.",
    // subimg3: projects.elite2,
    // workingProcess:"By incorporating an intuitive admin panel, clients can now easily add and manage projects, enhancing their engagement with the website and improving overall project visibility and management efficiency."
  },
  {
    id: 2,
    image: products.solarDryer,
    heading: "Solar Dryer - IOT based app",
    text: "Introducing our cutting-edge solar dryer with Phase Change Material for extended operation. Experience superior food quality with built-in moisture and temperature control, enhanced by intelligent machine learning solutions. Elevate your agricultural preservation with our innovative, quality-assured drying system..",
    conversionRate: 35,
    rating: 3.5,
    tagline1: "My Reasearch Room's Innovations",
    tagline2: "IOT Based Solar Dryer App",
    heroimg: products.solarDryer,
    client:"WeSolutize",
    services: ["Machine Learning", "Mobile App Development"],
    industry:"Agriculture",
    brief:"The proposed solution integrates solar energy with a dryer enhanced by Phase Change Material technology to extend operational hours. This combination, along with moisture and temperature control features, supported by machine learning algorithms, ensures improved food quality. ",
    subimg1: products.solarDryer2,
    subimg2: products.solarDryer3,
    challenge: "The challenge involves seamlessly integrating solar energy, Phase Change Material, and IoT technologies for optimal efficiency and performance while balancing moisture and temperature control. Additionally, developing machine learning algorithms to adapt to varying conditions and optimizing drying processes, along with designing a user-friendly IoT interface for enhanced user experience and ensuring long-term reliability and maintenance.",
    solution: "A solar energy based dryer along with introduction of Phase Change Material enables the dryer to operate for a longer period of time. With in - built moisture and temperature control coupled with intelligent machine learning solutions, enhanced food quality is an assurance. Induction of Internet of Things gives the user an easy contactless way to communicate with the machine.",
    subimg3: products.solarModule,
    subimg4: products.solarDryer4,
    workingProcess:"An IoT-based solar dryer represents a cutting-edge solution that leverages interconnected devices to revolutionize user experiences and streamline processes. By enabling seamless communication and data exchange between devices, IoT apps offer unprecedented levels of automation, control, and efficiency"
  },
  
];