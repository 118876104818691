import React from "react";
import { contact } from "../../../assets/contact";

const HeroSections = () => {
  return (
    <>
      <div className="h-full relative  bg-black text-white">
        <div className="md:pl-28 md:pb-60 pl-10 pb-32 w-[90%]  h-[90%]">
          <h2 className="md:text-4xl text-2xl pb-4 pt-28 font-normal">Careers</h2>
          <h2 className="md:text-6xl text-3xl font-semibold">
            We Seek Dreamers,
            <br /> Innovators, Disruptor
          </h2>
        </div>
      </div>
      {/* First Overlay */}
      <div className="h-fit pb-16   md:-mt-[80px]  flex flex-col  text-white   items-center bg-white ">
        <div
          className={` md:flex grow relative -mt-[70px]  w-[90%] h-fit rounded-3xl   bg-[#252525] bg-opacity-50 md:backdrop-blur-md `}
        >
          {/* Image */}
          <div className="md:w-1/2 w-full md:pr-10 ">
            <img
              src={contact.career_discussion}
              alt=""
              className="md:h-[418px] h-[300px] w-full object-cover rounded-t-3xl md:rounded-none"
            />
          </div>
          {/* Text */}
          <div className={`md:w-1/2  pt-16 pb-6 md:pr-32 p-5`}>
            {/* heading */}
            <h2 className="md:text-3xl text-xl font-bold">
              Join the WeSolutize Team
            </h2>
            {/* descripption */}
            <p className="md:text-xl text-[16px] font-medium mt-10">
              Innovate with the latest and greatest technologies & get to work
              on some of the coolest projects you can imagine.
            </p>

            {/* View Projects button */}
            <button className="bg-gradient-to-r from-[#088FC7] to-[#612980] text-white md:text-xl font-semibold md:w-[205px] md:h-[60px] mt-[53px] py-2 px-4 rounded-full ">
              <a href="https://forms.gle/r8gsRD6fe8Z5PDcr9" target="_blank" rel="noopener noreferrer"> Apply Now </a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSections;
