import React from "react";

const SectionThree = () => {
  return (
    <div className="h-full  flex justify-center items-center md:p-12 pt-10 mb-5">
    <div className=" w-[90%] bg-white drop-shadow-lg px-11  py-7 rounded-3xl">
      <div className=" md:text-3xl text-xl 2xl:text-4xl font-bold pb-7 ">Why Work With Us</div>
      <div className="md:text-xl text-[16px] 2xl:text-2xl font-normal italic">
        We believe in harnessing the collective brilliance of our diverse
        talents to craft innovative solutions and contribute to the
        ever-evolving digital landscape. Ready to join us and be at the
        forefront of transformative projects, taking your career in IT beyond
        limits?
      </div>
    </div>
    </div>
  );
};

export default SectionThree;
