import React from "react";
import { about } from "../../../assets/about";

const HeroSection = () => {
  
  return (
    
    <div
      className="md:h-screen h-[350px] relative  bg-black text-white"
      style={{
        backgroundImage: `url(${about.who_we_are})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="md:pl-28 pl-12 pb-32 md:pb-60 w-[90%] h-[90%]">
        <h2 className="md:text-4xl text-2xl pb-4 pt-28 font-normal">AI/ML Services</h2>
        <h2 className="md:text-6xl text-2xl font-semibold">
        Transform your business with AI & ML <br/> at your fingertips.        </h2>
      </div>
    </div>
  );
};

export default HeroSection;
