import React,{useEffect} from 'react'
import Header from '../../components/Header/index'
import HeroSections from '../../components/Careers/HeroSection/index'
import JobSection from '../../components/Careers/JobSection/index'
import Footer from '../../components/Footer/index'
import SectionThree from '../../components/Careers/Section3'

const Index = () => {
    useEffect(()=> {
        document.title = "Careers";
      }, [])
    return (
        <>
            <Header />
            <HeroSections />
            <JobSection />
            <SectionThree/>
            <Footer />
        </>
    )
}

export default Index