export const aiservicesdata = [
    {
      id: 1,
      title: "Real time decision support",
     para:"Machine learning algorithms excel at analyzing extensive data sets, uncovering hidden patterns and correlations that elude human analysts. Their ability to provide instant insights offers a valuable advantage in data-driven decision-making processes, unlocking new opportunities for innovation and efficiency."
    },
    {
      id: 2,
      title: "Risk Management and Mitigation",
     para:"Machine learning can analyze various risk factors and predict potential outcomes, enabling businesses to proactively identify, quantify, mitigate, and manage risks before they escalate into crucial problems."
    },
    {
      id: 3,
      title: "Predictive Maintenance",
      para:"By analyzing data from sensors and machinery, machine learning can predict equipment failures or maintenance needs, enabling proactive maintenance schedules and minimizing downtime."
      
    },
   
  ];
  