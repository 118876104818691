import React from "react";
import { useProject } from "../../../context/selectedServiceContext";
import { viewProjectsData } from "../../../data/viewprojectsdata";
import { Navigate } from "react-router-dom";

const DataSection = () => {
  const { selectedProject } = useProject() || {}; // Access the selected project from the context
  const projectId = selectedProject || 1;


  // Filter the viewProjectsData array to find the selected project
  const selectedProjectData = viewProjectsData.find(
    (project) => project.id === projectId
  );

   

  return (
    <div className="md:h-screen   pb-20 pt-10 md:px-28 px-14 md:flex gap-14  bg-black text-white">
      <div className="md:w-[30%] md:block hidden  ">
        <div className=" h-fit bg-[#16161B] border rounded-3xl border-[#656565] pb-6 px-6">
          {/* First Row */}
          <div className=" justify-between pt-6 items-center mb-5">
            {/* Heading */}
            <h2 className="md:text-xl text-[16px] font-bold">Client</h2>
            {/* Sub-paragraph */}
            <p className="md:text-lg font-normal">{selectedProjectData.client}</p>
          </div>
          {/* Separator */}
          <hr className="border-gray-400 mb-5" />

          {/* Second Row */}
          <div className=" justify-between items-center mb-5">
            {/* Heading */}
            <h2 className="md:text-xl text-[16px] font-bold">Services</h2>
            {/* List of items */}
            <ul className="md:text-lg font-normal">
              {selectedProjectData.services.map((service, index) => (
                <li key={index}>{service}</li>
              ))}
            </ul>
          </div>
          {/* Separator */}
          <hr className="border-gray-400 mb-5 " />

          {/* Third Row */}
          <div className=" justify-between  items-center">
            {/* Heading */}
            <h2 className="md:text-xl text-[16px] font-bold">Industry</h2>
            {/* Sub-paragraph */}
            <p className="md:text-lg font-normal">
              {selectedProjectData.industry}
            </p>
          </div>
        </div>
        <button
          type="button"
          class="text-white  text-base font-extrabold bg-[#121216]  border border-[#656565] focus:outline-none mt-7 md:mb-0 mb-4  rounded-full w-full  md:h-[60px] h-[40px]"
        >
          Start a Conversation
        </button>
      </div>

      <div className="md:w-[70%] p-2 md:overflow-y-scroll overflow-x-visible	no-scrollbar	  	">
        <h2 className=" md:text-2xl text-xl  2xl:text-5xl font-extrabold ">The Brief</h2>
        <p className=" text-justify pt-6 md:text-xl text-sm font-medium 2xl:text-2xl">
          {selectedProjectData.brief}
        </p>
        <div className="md:flex justify-between gap-2  h-fit mt-6">
          <img src={selectedProjectData.subimg1} alt="" className="md:w-1/2 md:mb-0 mb-5 rounded-xl" />
          <img src={selectedProjectData.subimg2} alt="" className="md:w-1/2 rounded-xl" />
        </div>
        <div className="mt-12">
          <h2 className=" md:text-2xl text-xl  2xl:text-5xl font-extrabold ">
            The Challenge
          </h2>
          <p className=" text-justify pt-6 md:text-xl text-sm font-medium 2xl:text-2xl">
            {selectedProjectData.challenge}
          </p>
        </div>
        <div className="mt-12">
          <h2 className=" md:text-2xl text-xl  2xl:text-5xl font-extrabold ">
            The Solution
          </h2>
          <p className=" text-justify pt-6 md:text-xl text-sm font-medium 2xl:text-2xl">
            {selectedProjectData.solution}
          </p>
        </div>
        <img src={selectedProjectData.subimg3} alt="" className="mt-6" />
        <div className="mt-9">
          <h2 className=" md:text-2xl text-xl  2xl:text-5xl font-extrabold ">
            Our Working Process
          </h2>
          <p className=" text-justify pt-6 md:text-xl text-sm font-medium 2xl:text-2xl">
            {selectedProjectData.workingProcess}
          </p>
        </div>
        <div className="mt-9 grid md:grid-cols-2 grid-cols-1 gap-4  text-black">
          <div className="bg-[#E1E1E1] rounded-2xl py-5 md:px-6 px-3 col-span-1">
            <h2 className="md:text-2xl text-xl  font-normal mb-5">Planning / Design</h2>
            <p className="text-base font-normal text-[#000422]">
              Crafting innovative solutions through meticulous planning and
              cutting-edge design strategies.
            </p>
          </div>
          <div className="bg-[#E1E1E1] rounded-2xl py-5 px-6 col-span-1">
            <h2 className="md:text-2xl text-xl  font-normal mb-5">Development</h2>
            <p className="text-base font-normal text-[#000422]">
              Transforming ideas into robust software solutions with expert
              development practices.{" "}
            </p>
          </div>
          <div className="bg-[#E1E1E1] rounded-2xl py-5 px-6 col-span-1">
            <h2 className="md:text-2xl text-xl  font-normal mb-5">Testing</h2>
            <p className="text-base font-normal text-[#000422]">
              Ensuring quality and reliability through rigorous testing and
              validation processes.{" "}
            </p>
          </div>
          <div className="bg-[#E1E1E1] rounded-2xl py-5 px-6 col-span-1">
            <h2 className="md:text-2xl text-xl  font-normal mb-5">Deployment</h2>
            <p className="text-base font-normal text-[#000422]">
              Seamlessly launching and integrating software solutions for
              optimal performance and user experience.{" "}
            </p>
          </div>
        </div>
        <button
          type="button"
          class="text-white md:hidden  block text-base font-extrabold bg-[#121216]  border border-[#656565] focus:outline-none mt-7 md:mb-0 mb-4  rounded-full w-full  md:h-[60px] h-[40px]"
        >
          Start a Conversation
        </button>
      </div>
    </div>
  );
};

export default DataSection;
