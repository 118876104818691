import React from 'react'
import HeadSection from '../../components/AIServices/HeadContent'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import SectionTwo from '../../components/AIServices/Section2'
import SectionThree from '../../components/AIServices/Section3'
import SectionFour from '../../components/AIServices/Section4'

const AIServices = () => {
  return (
    <>
    <Header/>
    <HeadSection/>
    <SectionTwo/>
    <SectionThree/>
    <SectionFour/>
    <Footer/>
    </>
  )
}

export default AIServices