import Footer from "../../components/Footer";
import Header from "../../components/Header";

import HeroSection from "../../components/Home/HeroSection";
import SectionTwo from "../../components/Home/Section2";
import SectionThree from "../../components/Home/Section3";
import SectionFour from "../../components/Home/Section4";
import SectionFive from "../../components/Home/Section5";
import SectionSix from "../../components/Home/Section6";




const Home = () => {
  return (
    <>
      <Header/>
      <HeroSection />
      <SectionTwo/>
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix/>
      <Footer />
    </>
  );
};

export default Home;
