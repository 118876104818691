import { testimonials } from "../assets/testemonials/testemonials";

export const testimonialData = [
    {
      image: testimonials.ashish_vajir,
      title: 'Ashish Vajir',
      description: 'The recent website redesign of my company has truly exceeded my expectations. The new layout is not only sleek and visually appealing but also remarkably user-friendly. Navigating through the site has become a seamless experience, making my overall interaction with the platform both enjoyable and efficient. I am thoroughly impressed with the redesign, as it has significantly enhanced the usability and aesthetics of the website, creating a more engaging and satisfying browsing experience for users like myself',
      position : 'CEO, Elite Consulting Engineering',
     
    },
    {
      image: testimonials.avinash_magdum,
      title: 'Avinash Magdum',
      description: 'This company is truly exceptional in every way. Their service is outstanding, marked by a level of excellence that sets them apart. The teams professionalism, expertise, and dedication to exceeding expectations are evident throughout the entire process. Communication with them was seamless, and the quality of their work went above and beyond what I had anticipated. I wholeheartedly recommend this company to anyone in need of reliable and top-quality services.',
      position : 'CEO, Whizkey Pvt. Ltd.',
    },
    {
      image: testimonials.sunil_sutar,
      title: 'Sunil Sutar',
      description: 'Throughout the project, communication was seamless and transparent. The team made sure to keep me updated on progress, milestones, and any potential challenges that arose. Their collaborative approach encouraged open dialogue, enabling us to address any concerns promptly and make necessary adjustments along the way. This level of communication fostered a strong sense of trust and partnership, making the entire process smooth and efficient',
      position : 'CAE Engineer',
    },
    {
      image: testimonials.sumedh_joshi,
      title: 'Sumedh Joshi',
      description: 'I recently engaged with a top-tier software development and AI ML services company for a project, and I must say, the experience was nothing short of exceptional. From the initial consultation to the final delivery, every step of the process was handled with professionalism and expertise. The teams dedication to understanding my requirements and delivering innovative solutions truly set them apart in the industry.',
      position : 'Mechanical Engineer, KBR Inc',
    },
    {
      image: testimonials.omkar_patil,
      title: 'Omkar Patil',
      description: 'One of the standout aspects of working with this company was their unparalleled expertise in software development and AI ML services. Their team of skilled professionals demonstrated a deep understanding of cutting-edge technologies and trends, allowing them to propose innovative solutions that exceeded my expectations. Their ability to blend creativity with technical proficiency resulted in a product that not only met but surpassed my business objectives.',
      position : 'Senior Engineer, Cummins',
    },
    {
      image: testimonials.ganesh_bhosale,
      title: 'Ganeshraj Bhosale',
      description: 'I have had the pleasure of working with this software development company, and I must say their solutions have significantly improved our workflow efficiency. The quality of their work, attention to detail, and seamless collaboration with our team have been exceptional. As an employee utilizing their services, I can confidently vouch for their expertise.',
      position : 'Co Founder, Prygma LLP',
    },
    {
      image: testimonials.ganesh_joshi,
      title: 'Ganesh Joshi',
      description: 'This software development company has played a crucial role in our business transformation. Their tailored solutions and technical proficiency have had a significant impact on our growth. As a CTO, I appreciate their commitment to understanding our unique needs and delivering results that drive success. I highly recommend their services.',
      position : 'CTO, Prygma LLP',
    },
    {
      image: testimonials.dipak_patil,
      title: 'Dipak Patil',
      description: 'Working with this company was remarkable due to their exceptional proficiency in software development and AI ML services. Their team showcased a profound grasp of advanced technologies and trends, enabling them to suggest groundbreaking solutions that went beyond what I had envisioned. By seamlessly combining creativity with technical expertise, they delivered a product that not only fulfilled but exceeded my business goals.',
      position : 'MD, VimalsSonic LTD',
    },
    {
      image: testimonials.amol_deshpande,
      title: 'Amol Deshpande',
      description: 'The software development services provided by this company have truly elevated our operations. Their expertise, professionalism, and dedication to client satisfaction are commendable. As a CEO, I highly recommend their services for any business looking to innovate and grow.',
      position : 'CEO , Deshpande Infra',
    },
  ];