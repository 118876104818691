import { projects } from "../assets/projects/projects";

// Sample project data
export const viewProjectsData = [
  {
    id: 1,
    image: projects.erpBackground,
    heading: "ERP Software",
    text: "NikolEV ERP is a specialized software designed to streamline operations for electric vehicle businesses.",
    conversionRate: 39,
    rating: 4.5,
    tagline1: "Empowering Electric Mobility",
    tagline2: "For Project Management",
    heroimg: projects.erpheroimage,
    client: "WeSolutize Technologies, Pune",
    services: ["Web Development"],
    industry: "It Industry",
    brief: "It integrates project management, inventory, vendor coordination, and financial tracking into a single platform.",
    subimg1: projects.erp1,
    subimg2: projects.erp2,
    challenge: "Integrating various business functions such as project management, inventory, vendor coordination, and financial tracking into a single platform can be complex.",
    solution: "Employ advanced data management techniques and scalable cloud infrastructure.",
    subimg3: projects.erp3,
    workingProcess: "With real-time analytics and automated workflows, it boosts efficiency, reduces costs, and supports business growth in the EV industry. "
  },
  {
    id: 2,
    image: projects.CRM_Bg,
    heading: "CRM Dashboard",
    text: "Revolutionize the traditional car wash industry by creating an innovative mobile app solution that enhances user experience, streamlines operations, and boosts business revenue.",
    conversionRate: 39,
    rating: 4.5,
    tagline1: "Explore Our Dynamic CRM software",
    tagline2: "for Project Management",
    heroimg: projects.crm_hero_img,
    client: "My Research Room",
    services: ["Web Development"],
    industry: "It Industry",
    brief: "Designing a comprehensive CRM dashboard with distinct user roles for admin, employee, and team lead to streamline project management and task allocation.",
    subimg1: projects.crm1,
    subimg2: projects.crm2,
    challenge: "Crafting a user-friendly interface that accommodates the varying needs and permissions of admin, employee, and team lead within the CRM system.",
    solution: "By implementing role-based access control, our CRM system optimizes efficiency by empowering administrators to efficiently manage users and projects. Meanwhile, team leads are equipped with the capability to seamlessly assign tasks, while employees can effortlessly update task progress. This approach ensures that each user group has the tools they need to perform their respective roles effectively within the system, promoting streamlined collaboration and productivity across the board.",
    subimg3: projects.crm3,
    workingProcess: "Through our comprehensive approach to web development, we delivered a game-changing solution that empowers car wash businesses to thrive in today's digital landscape."
  },
  {
    id: 3,
    image: projects.pathoProBackground,
    heading: "PathoPro - Lab Management System",
    text: "Generate customizable reports for various aspects of lab operations",
    conversionRate: 46,
    rating: 4.5,
    tagline1: "Explore Our PathoPro",
    tagline2: "for Lab Management System",
    heroimg: projects.pathoProheroimage,
    client: "Patho Pro",
    services: ["Patient Record Management", "Test Scheduling", "Storage Management"],
    industry: "Medical Sector",
    brief: "Initial setup and configuration tailored to your lab's specific needs.",
    subimg1: projects.pathoPro1,
    subimg2: projects.pathoPro2,
    challenge: "Streamline lab operations and reduce manual workload",
    solution: "Comprehensive training for staff and ongoing support to ensure smooth operation.",
    subimg3: projects.pathoPro3,
    workingProcess: "Seamless integration with existing systems and equipment."
  },
  {
    id: 4,
    image: projects.elite5,
    heading: "Elite Website Redesign",
    text: "We revamped an engineering consulting website, integrating an admin panel for clients to effortlessly add projects. This enhancement aims to streamline project management and showcase client work more efficiently.",
    conversionRate: 46,
    rating: 4.5,
    tagline1: "Explore Our Dynamic Website Design for",
    tagline2: "Elite Engineering",
    heroimg: projects.elite1,
    client: "Elite Engineering",
    services: ["UX/UI Design", "Web Development", "SEO Optimization"],
    industry: "Engineering Consultation",
    brief: "We revamped an engineering consulting website, integrating an admin panel for clients to effortlessly add projects. This enhancement aims to streamline project management and showcase client work more efficiently.",
    subimg1: projects.elite3,
    subimg2: projects.elite4,
    challenge: "In enhancing the engineering consulting website with an admin panel for client project management, our focus was on creating a user-friendly interface that caters to clients with diverse technical backgrounds. Ensuring data security was paramount, leading us to implement robust measures for secure data handling and access control within the admin panel. Seamless integration with the existing website architecture was achieved through careful planning and API utilization, guaranteeing a cohesive and efficient system for clients to add and manage projects with ease and confidence.",
    solution: "In the redesign of the engineering consulting website and the implementation of an admin panel for client project management, our approach centered on user-centric design by emphasizing a clean and intuitive interface for effortless project uploads. Security was a top priority, leading to the implementation of role-based access control to protect client data effectively. Through strategic API integration, we seamlessly connected the admin panel with the website backend, ensuring smooth data transfer and enhancing overall system efficiency for streamlined project management and enhanced data security.",
    subimg3: projects.elite2,
    workingProcess: "By incorporating an intuitive admin panel, clients can now easily add and manage projects, enhancing their engagement with the website and improving overall project visibility and management efficiency."
  },
  {
    id: 5,
    image: projects.nickol1,
    heading: "Nikol EV Website",
    text: "We’ve developed a new responsive website for Nikol EV, a leader in EV charging solutions. The site allows clients to manage projects efficiently and access information seamlessly on any device, enhancing the overall user experience and supporting sustainable charging infrastructure.",
    conversionRate: 56,
    rating: 4.5,
    tagline1: "Explore Our Dynamic Website Design for",
    tagline2: "Nikol EV",
    heroimg: projects.nickol7,
    client: "NIKOL EV",
    services: ["UX/UI Design", "Web Development", "SEO Optimization"],
    industry: "EV CHARGING",
    brief: "Nikol EV website to streamline the addition and management of electric charging projects, enhancing project visibility and showcasing work more efficiently."
    ,
    subimg1: projects.nickol5,
    subimg2: projects.nickol4,
    challenge: "In enhancing Nikol Ev  website, our focus was on creating an intuitive interface for clients to easily add and manage electric charging projects. Ensuring robust data security was crucial, leading us to implement stringent measures for secure data handling and access control. The challenge involved integrating these new features seamlessly with the static website, using careful planning and API integration to provide a smooth and efficient experience for managing and showcasing projects.",
    solution: "In the redesign of the Nikol Ev  website and implementation of an admin panel for managing EV charging stations, our approach focused on user-centric design, prioritizing a clean, intuitive interface for easy station management. Security remained a key focus with role-based access control to safeguard sensitive data. Strategic API integration connected the admin panel to the backend, enabling smooth data transfer and optimizing system efficiency, ensuring streamlined station management and enhanced data protection.",
    subimg3: projects.nickol3,
    workingProcess: "By incorporating an intuitive, clients can now easily add and manage projects, enhancing their engagement with the website and improving overall project visibility and management efficiency."
  },

  {
    id: 6,
    image: projects.carwash5,
    heading: "Car Washing App",
    text: "Revolutionize the traditional car wash industry by creating an innovative mobile app solution that enhances the user experience, streamlines operations, and boosts business revenue.",
    conversionRate: 45,
    rating: 4.0,
    tagline1: "Experience the Future of Car Washing with",
    tagline2: "Our Custom-Built Mobile App Solution.",
    heroimg: projects.carwash1,
    client: "Jakob Ekstrom Bothman",
    services: ["UX/UI Design", "Mobile App Development"],
    industry: "Engineering Consultation",
    brief: "Revolutionize the traditional car wash industry by creating an innovative mobile app solution that enhances the user experience, streamlines operations, and boosts business revenue.",
    subimg1: projects.carwash3,
    subimg2: projects.carwash4,
    challenge: "The car wash industry is ripe for modernization, yet if faces challenges in terms of outdated processes,a lack of customer engagement, and inefficient management systems. Developing a mobile app that addresses these pain points while ensuring seamless functionality across various devices poses a significant challenge.",
    solution: "Our team leveraged a decade of expertise in mobile app development to craft a cutting-edge solution tailored specifically for the car wash industry. Through intensive research and user-centric design, we conceptualized a feature-rich app that revolutionizes the way car wash businesses operate and engage with their customers.",
    subimg3: projects.carwash2,
    workingProcess: "Through our comprehensive approach to mobile app development, we delivered a game-changing solution that empowers car wash businesses to thrive in today's digital landscape."
  },
  {
    id: 7,
    image: projects.cpm5,
    heading: "CPM: IOT Based Mobile App",
    text: "An IoT-based healthcare application designed for physiotherapists, surgeons, and patients facilitates seamless control of Continuous Passive Motion (CPM) machines through the app.",
    conversionRate: 52,
    rating: 4.5,
    tagline1: "Transform healthcare with our IoT app, connecting",
    tagline2: "users for enhanced rehabilitation.",
    heroimg: projects.cpm1,
    client: "Whizkey Pvt.Ltd",
    services: ["UX/UI Design", "Web Development", "SEO Optimization"],
    industry: "Healthcare",
    brief: "We revamped an engineering consulting website, integrating an admin panel for clients to effortlessly add projects. This enhancement aims to streamline project management and showcase client work efficiently.",
    subimg1: projects.cpm3,
    subimg2: projects.cpm4,
    challenge: "In enhancing the engineering consulting website with an admin panel for client project management, our focus was on creating a user-friendly interface that caters to clients with diverse technical backgrounds. Ensuring data security was paramount, leading us to implement robust measures for secure data handling and access control within the admin panel. Seamless integration with the existing website architecture was achieved through careful planning and API utilization, guaranteeing a cohesive and efficient system for clients to add and manage projects with ease and confidence.",
    solution: "In the redesign of the engineering consulting website and the implementation of an admin panel for client project management, our approach centered on user-centric design by emphasizing a clean and intuitive interface for effortless project uploads. Security was a top priority, leading to the implementation of role-based access control to protect client data effectively. Through strategic API integration, we seamlessly connected the admin panel with the website backend, ensuring smooth data transfer and enhancing overall system efficiency for streamlined project management and enhanced data security.",
    subimg3: projects.cpm2,
    workingProcess: "By incorporating an intuitive admin panel, clients can now easily add and manage projects, enhancing their engagement with the website and improving overall project visibility and management efficiency."
  },

];