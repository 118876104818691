import React from 'react'
import userContact from "../../../assets/userContact.png"
const SectionFour = () => {
  return (
    <div className="h-auto pb-20 flex flex-col justify-center items-center bg-[#292078] text-white">
       <h2 className='text-center text-3xl mb-5 font-semibold w-full mt-10'>MRR's AI Detection System</h2>

        <div className='w-[80%] h-auto px-8 pt-8 py-8 rounded-xl cursor-pointer mb-5 bg-opacity-50 bg-[#595196]'>
            <img src={userContact} className='h-[23px] w-[25px]' />
            <div className=' flex-felx-col justify-center items-start'>
                <h1 className='font-bold text-xl pb-2 '>The Future of Maintenance</h1>
                <p className='text-sm'>Harness the power of advanced AI with our game-changing Detection System, meticulously 
                    spotting defects like cracks and black spots to prevent severe module issues. 
                    Continuously evolving, our AI is expanding to detect a wide range of defects, 
                    from dust build-up to elusive microcracks, ensuring peak performance for every solar module. 
                    Our goal is to optimize every module's efficiency, pushing boundaries in solar maintenance.
                </p>
            </div>
        </div>

        <div className='flex lg:flex-row flex-col justify-between gap-5 items-center w-[80%]  '>
            <div className='h-[100%] lg:w-[50%] w-full px-8 pt-6 py-6 rounded-xl cursor-pointer mb-5  bg-[#8e8e8e]'>
                <img src={userContact} className='h-[23px] w-[25px]' />
                <div className=' flex-felx-col justify-center items-start mb-5'>
                    <h1 className='font-bold text-xl pb-2 w-[70%]'>Examining Solar Panel Flaws</h1>
                    <p className='text-sm'>Our operations reveal critical insights into solar module defects. 
                        Hot spots reduce efficiency and pose fire risks, while invisible microcracks and dust accumulation impact performance.
                     These examples highlight the diverse range of potential defects in solar modules.
                    </p>
                </div>
            </div>


            <div className=' h-[100%] lg:w-[50%] w-full px-8 pt-6 py-6 rounded-xl cursor-pointer mb-5 bg-opacity-40 bg-[#595196]'>
                <img src={userContact} className='h-[23px] w-[25px]' />
                <div className='flex-felx-col justify-center items-start mb-5'>
                    <h1 className='font-bold text-xl pb-2 w-[70%]'>Early Detection and Prevention</h1>
                    <p className='text-sm'>MRR's AI Detection System ensures early identification of solar module issues, preserving efficiency, enhancing safety, 
                        and preventing costly late-stage repairs or replacements.
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SectionFour
