import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { testimonialData } from "../../../data/home_section5_data";

const Carousel = () => {

  const sliderRef = useRef(null);

  

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows:false,
    responsive: [
      {
        breakpoint: 1024, // Adjust breakpoint as needed
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // Adjust breakpoint as needed
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    
    <Slider ref={sliderRef} {...settings} >
      {testimonialData.map((slide, index) => (
        <div key={index} className="mb-14">
          <div
            
            className=" bg-[#16020214] bg-opacity-15  backdrop-blur-sm w-[90%] h-[500px]   rounded-3xl"
          >
            <div className="bg-[#002286D9] flex flex-row items-center h-[30%] p-5 rounded-t-3xl">
              <img
                src={slide.image}
                alt={`Slide ${index + 1}`}
                className=" h-24 w-24 rounded-full mr-2"
              />
              <div >
                <h3 className="text-white text-xl font-medium ">{slide.title}</h3>
                <p className="text-white text-lg font-normal">{slide.position}</p>
              </div>
            </div>
            <div className=" pt-8 px-5 overflow-y-auto h-[70%] custom-scrollbar" style={{scrollbarWidth:"thin",scrollbarColor:"transparent transparent"}}>
              <p className="text-[#565454] text-base font-normal ">{slide.description}</p>
            </div>
          </div>
        </div>
       
      ))}
    </Slider>
  );
};
export default Carousel;
