export const products = {
    solarModule: require("./solarModule.png"),
    
    // elite2: require("./elite_2.png"),
    // elite3: require("./elite_3.png"),
    // elite4: require("./elite_4.png"),
    // elite5: require("./elite5.png"),
    // elite6: require("./elite6.png"),

    // cpm1: require("./cpm1.png"),
    // cpm2: require("./cpm2.png"),
    // cpm3: require("./cpm3.png"),
    // cpm4: require("./cpm4.png"),
    // cpm5:require("./cpm5.png"),
    // cpm6:require("./cpm6.png"),

    solarDryer: require("./SolarDryerProject.png"),
    solarDryer2: require("./SolarDryer2.png"),
    solarDryer3: require("./SolarDryer3.png"),
    solarDryer4: require("./SolarDryer4.png"),
    // carwash2: require("./carwash2.png"),
    // carwash3: require("./carwash3.png"),
    // carwash4: require("./carwash4.png"),
    // carwash5: require("./carwash5.png"),
    // carwash6: require("./carwash6.png")

   
  };
  