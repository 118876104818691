import React,{useState} from 'react'
import Logo from "./../../../assets/Modal_Images/Logo.png"
import Mac1 from "./../../../assets/Modal_Images/erpsolution.png"
import Mac2 from "./../../../assets/Modal_Images/MacBook1.png"
import Mac3 from "./../../../assets/Modal_Images/hospitalManagement.png"
import Mac4 from "./../../../assets/Modal_Images/pathoPro.png"
import bg from "./../../../assets/Modal_Images/bg.png"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { ImCancelCircle } from "react-icons/im";
import { useNavigate } from 'react-router-dom'
import "../HeroSection/index.css"
const Modal = ({setOpen}) => {

    const navigate=useNavigate()
    const [closing, setClosing] = useState(false);
    const ModalData=[
        {
            title:'ERP : EV Charging Stations',
            description:'It integrates project management, inventory, vendor coordination, and financial tracking into a single platform.',
            image:Mac1,
        },
        {
            title:'Elevate Your Business with Next-Generation CRM Software',
            description:'Unlock the potential of your business with our advanced CRM software. Seamlessly integrate customer data, automate tasks, and drive growth like never before.',
            image:Mac2,
        },
        {
            title:'Hospital Management System Dashboard',
            description:'Streamlining Hospital operations with smart management and automation for better patient cure.',
            image:Mac3,
        },
        
        {
            title:'PathoPro: LAB MANAGEMENT SYSTEM',
            description:'Transform your Pathology Lab with Advanced Management System. The app provides real-time updates, seamless integration with lab equipment and secure data handling.',
            image:Mac4,
        },
    ]
    const handleClose = () => {
        setClosing(true);
        
        setTimeout(() => {
          setOpen(false);
        }, 300); // Match the duration of the closing animation
      };

  return (
    <div  className='fixed top-10 left-0 w-full h-full z-[30] bg-black bg-opacity-50 flex justify-center items-center'>

{/* condition for this block backgroundImage */}
      <div className={`modal-container bg-white h-[70%] lg:relative border border-white lg:w-[70%] w-[90%] flex lg:flex-row flex-col gap-4 rounded-xl overflow-y-hidden  lg:bg-cover lg:bg-none ${closing ? 'closing' : ''}`} style={{backgroundRepeat:"no-repeat"}}>
        <div className='h-[100%] lg:w-[80%] w-full bg-cover'>
            <img src={bg} className='w-full h-full' />
        </div>
        <div className='w-full lg:h-[15%] h-[50px] flex justify-between  lg:px-6 px-2 absolute top-0'>
            <img src={Logo} className='h-full' /> 
            <ImCancelCircle  size={24} onClick={handleClose} className='cursor-pointer relative top-2 lg:text-[black] text-[white]' />
        </div>
        <div className='h-[85%] absolute top-[15%] w-[100%]'>
        <Carousel  infiniteLoop autoPlay showArrows={false} showStatus={false} showThumbs={false} className='h-[100%] p-4 custom-carousel w-[100%]'>
            {
                ModalData.map((data,id)=>{
                   return(
                    <div key={id} className='flex lg:flex-row flex-col gap-2 py-10 w-[100%]'>
                        <div className='text-white lg:w-[50%] h-auto flex flex-col justify-between lg:px-5'>
                            <h1 className='font-semibold lg:text-[30px] text-[20px] text-left'>{data.title}</h1>
                            <p className='text-[#BBBBBB] text-[14px] text-left mt-3 mb-4'>{data.description}</p>
                            <button  onClick={()=>navigate('/contactus')} className='w-[150px] relative  bg-gradient-to-r from-[#088FC7] to-[#612980] p-2 rounded-full cursor-pointer float-left'>Contact Us</button>
                        </div>

                        <div className='md:w-[50%] h-auto p-2'>
                            <img src={data.image} className='h-[250px] w-full object-contain'/>
                        </div>
                    </div>
                   )
                })
            }
        </Carousel>
        </div>
      </div>
    </div>
  )
}

export default Modal
