import React from "react";
import { contact } from "../../../assets/contact";
import { useNavigate } from "react-router-dom";

const SectionThree = () => {
  const navigate= useNavigate();
  return (
    <div
      className="h-full bg-cover md:py-16 py-8 md:px-28 px-14 font-medium md:text-5xl text-3xl md:flex items-center justify-center"
      style={{
        backgroundImage: `url(${contact.contactbg})`,
        backgroundPosition: "left center",
      }}
    >
      <div className="md:w-3/4 ">
        <h2>
          Want to Get Top-notch AI and <br />
          ML Services?
        </h2>
      </div>

      <div className="md:w-1/4 flex md:flex-col justify-center items-center md:mt-0 mt-4 gap-6">
        <button className=" text-black md:text-xl text-[12px] flex justify-center items-center font-semibold md:w-[205px] md:h-[60px] w-[170px] h-[35px] py-2 md:px-4 rounded-full border-black border" onClick={()=>navigate("/contactus")}>
          Know More
        </button>
        <button className="bg-gradient-to-r from-[#088FC7] to-[#612980] flex justify-center items-center text-white md:text-xl text-[12px] font-semibold md:w-[205px] md:h-[60px] w-[170px] h-[35px]  py-2 md:px-4 rounded-full " onClick={()=>navigate("/contactus")}>
          Schedule a call
        </button>
      </div>
    </div>
  );
};

export default SectionThree;
