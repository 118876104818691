import React from "react";
import { about } from "../../../assets/about";

const LeaderShip = () => {
  
  return (
    <div
      className="h-[70vh] relative bg-black text-white md:px-28 px-[40px] flex flex-col  justify-center gap-10 "
      style={{
        backgroundImage: `url(${about.leadership})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <h2 className="md:text-5xl text-2xl font-semibold text-left">
      “Harness data, AI, and progressive methods to reshape enterprises, foster resilience, and uncover pathways to <span className="bg-gradient-to-r from-[#088FC7] to-[#612980] text-transparent bg-clip-text">new growth .</span>”
      </h2>

      <h2 className="md:text-2xl text-xl font-normal left-0 ">
      Appasaheb Raul / CEO 
      </h2>
      
    </div>
  );
};

export default LeaderShip;
