const jobData = {
  "MERN Stack Intern": {
    responsibilities: [
      "•Understand company goals and challenges to plan AI strategies.",
      "•Develop and maintain web applications using the MERN stack.",
      "•Collaborate with team members to design, build, and deploy new features.",
      "•Participate in code reviews and contribute to best practices.",
      "•Work on live projects, gaining real-world experience.",
    ],
    qualifications: [
      "Pursuing or holding a degree in Computer Science, Information Technology, or a related",
      "field or course in MERN Stack.",
      "Strong understanding of JavaScript, HTML, and CSS.",
      "Familiarity with MongoDB/ My SQL, Express.js, React, and Node.js.",
      "Strong problem-solving skills and attention to detail.",
      "Ability to work both independently and in a team environment.",
    ],
    duration: "6 Months",
    location: "On-site, Office 210, City Center, Hinjewadi Phase 1, Pune.",

  },

  "Front-end Developer": {
    responsibilities: [
      "• Implement visual and interactive elements for user engagement on websites",
      "• Collaborate with UI/UX designers to ensure the technical feasibility of designs.",
      "• Optimize application performance and responsiveness for various devices and browsers.",
      "• Conduct code reviews and provide feedback to improve code quality and maintainability.",
      "• Stay updated on front-end development trends and best practices.",
    ],
    qualifications: [
      "• Bachelor's/Master's degree in computer science, software engineering, or a related field.",
      "• Proficiency in HTML, CSS, JavaScript, and front-end frameworks like React.js.",
      "• Experience with version control systems such as Git and understanding of CI/CD pipelines.",
      "• Strong problem-solving skills and ability to troubleshoot complex issues.",
      "• Excellent communication and collaboration skills.",
    ],
    duration: "6 Months",
    location: "On-site, Office 210, City Center, Hinjewadi Phase 1, Pune.",
    experience: "2+ years",
  },
  "Back-end Developer": {
    responsibilities: [
      "• Implement visual and interactive elements for user engagement on websites",
      "• Collaborate with UI/UX designers to ensure the technical feasibility of designs.",
      "• Optimize application performance and responsiveness for various devices and browsers.",
      "• Conduct code reviews and provide feedback to improve code quality and maintainability.",
      "• Stay updated on front-end development trends and best practices.",
    ],
    qualifications: [
      "• Bachelor's/Master's degree in computer science, software engineering, or a related field.",
      "• Proficiency in HTML, CSS, JavaScript, and front-end frameworks like React.js.",
      "• Experience with version control systems such as Git and understanding of CI/CD pipelines.",
      "• Strong problem-solving skills and ability to troubleshoot complex issues.",
      "• Excellent communication and collaboration skills.",
    ],
    duration: "6 Months",
    location: "On-site, Office 210, City Center, Hinjewadi Phase 1, Pune.",
    experience: "2+ years",
  },
  Tester: {
    responsibilities: [
      "• Implement visual and interactive elements for user engagement on websites",
      "• Collaborate with UI/UX designers to ensure the technical feasibility of designs.",
      "• Optimize application performance and responsiveness for various devices and browsers.",
      "• Conduct code reviews and provide feedback to improve code quality and maintainability.",
      "• Stay updated on front-end development trends and best practices.",
    ],
    qualifications: [
      "• Bachelor's/Master's degree in computer science, software engineering, or a related field.",
      "• Proficiency in HTML, CSS, JavaScript, and front-end frameworks like React.js.",
      "• Experience with version control systems such as Git and understanding of CI/CD pipelines.",
      "• Strong problem-solving skills and ability to troubleshoot complex issues.",
      "• Excellent communication and collaboration skills.",
    ],
    duration: "6 Months",
    location: "On-site, Office 210, City Center, Hinjewadi Phase 1, Pune.",
    experience: "2+ years",
  },
  "UI/UX Designer": {
    responsibilities: [
      "• Conduct user research and evaluate feedback.",
      "• Create wireframes, prototypes, and UI mockups.",
      "• Design graphic user interface elements.",
      "• Perform usability testing and troubleshoot UX problems.",
      "• Translate UI/UX designs into functional code.",
      "• Collaborate with product managers and engineers.",
      "• Optimize applications for speed and scalability.",
      "• Adhere to style standards on fonts, colors, and images."

    ],
    qualifications: [
      "• Bachelor’s degree in Design, Computer Science, or a related field.",
      "• Proficiency in design software like Figma.",
      "• Strong understanding of HTML, CSS, JavaScript, and front - end frameworks(e.g., React, Angular).",
      "• Understanding of layout aesthetics and mobile - first design principles.",
      "• Familiarity with user - centered design principles and practices.",
      "• Excellent communication and teamwork skills.",
      "• Strong problem- solving skills and attention to detail.",
      "• Ability to manage multiple projects and meet deadlines.",
      "• Up - to - date with the latest UI/ UX trends, techniques, and technologies.",

    ],
    duration: "6 Months",
    location: "On-site, Office 210, City Center, Hinjewadi Phase 1, Pune.",
    experience: "0-1 years",
  },
  "AI ML Intern": {
    responsibilities: [
      "•Develop and implement machine learning models.",
      "•Preprocess and analyze large datasets.",
      "•Collaborate with team members to integrate AI solutions into applications.",
      "•Experiment with different algorithms and techniques to improve model performance.",
      "•Participate in code reviews and contribute to best practices.",
      "•Work on live projects, gaining real-world experience."
    ],
    qualifications: [
      "•Pursuing or holding a degree in Computer Science, Data Science, Artificial Intelligence, or a related field.",
      "•Strong understanding of Python and relevant libraries.",
      "•Familiarity with machine learning frameworks such as TensorFlow and scikit-learn.",
      "•Experience with data manipulation and analysis using Pandas and NumPy.",
      "•Strong problem-solving skills and attention to detail.",
      "•Ability to work both independently and in a team environment.",

    ],
    duration: "6 Months",
    location: "On-site, Office 210, City Center, Hinjewadi (Phase 1) Pune.",

  },
};

export default jobData;
