// components/WhyChooseUs.js
import React from "react";

const SectionTwo = () => {
  return (
    <div className="md:h-full bg-cover md:py-16 py-8 md:px-28 px-14 h-screen  flex items-center   bg-gradient-to-r from-[#088FC7] to-[#612980]">
      <div className="md:w-3/4 w-full text-white ">
        <h2 className="font-medium md:text-4xl text-2xl">
          Enhancing technology experiences with <br />
          Modern Business intelligence solutions
        </h2>
        <h2 className="lg:text-xl pt-7  text-[16px] text-justify">
          WeSolutize is a team of digital transformation experts with diverse skills, delivering custom and world-class digital solutions
          to businesses of all scales. From digital product design to development, we deliver remarkable digital experiences.
        </h2>
      </div>
    </div>
  );
};

export default SectionTwo;
