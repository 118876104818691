import React, { useState } from "react";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { contact } from "../../../assets/contact";
import { useNavigate } from "react-router-dom";
import { useProject } from "../../../context/selectedServiceContext";

const ServiceSection = ({ title, description }) => {
  const [hovered, setHovered] = useState(false); // State to track hover
  const { setProject } = useProject();
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setHovered(true); // Set hovered to true on mouse enter
  };

  const handleMouseLeave = () => {
    setHovered(false); // Set hovered to false on mouse leave
  };

  const handleClick = () => {
    if (
      ["Web Development", "Mobile App Development", "UX/UI Design"].includes(
        title
      )
    ) {
      setProject(title);
      console.log("title", title);
      navigate("/services");
    } 
  };

  // Render the arrow only for specific titles
  const renderArrow = [
    "Web Development",
    "Mobile App Development",
    "UX/UI Design",
  ].includes(title);

  return (
    <div
      className={`w-full md:h-72 mx-auto border-b-2 md:border-b-0 md:mt-10 md:rounded-2xl rounded-none p-6 relative cursor-pointer ${
        hovered ? "bg-gray-200 rounded-2xl" : "bg-white bg-opacity-15 md:backdrop-blur-lg  "
      } transition-colors`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <h2
        className={`md:text-3xl text-xl font-medium ${
          hovered
            ? " bg-gradient-to-r from-[#088FC7] to-[#612980] text-transparent bg-clip-text"
            : "text-black"
        } transition-colors`}
      >
        {title}
      </h2>
      <div
        className="w-full md:h-8 h-4  border-l-4 my-2 mx-auto"
        style={{ borderLeftColor: hovered ? "rgba(8, 143, 199)" : "#D9D9D9" }}
      ></div>
      <p className="mt-2 text-sm font-normal text-[#000422] ">{description}</p>
      {/* Right arrow icon */}
      {renderArrow && hovered && (
        <div className="absolute bottom-4 left-[10%] transform -translate-x-1/2">
          <HiOutlineArrowLongRight
            className="h-12 w-12 "
            style={{ color: hovered ? "rgba(8, 143, 199)" : "black" }}
          />
        </div>
      )}
    </div>
  );
};

const ServicesList = () => {
  const services = [
    {
      title: "Web Development",
      description:
        "Empower your business with our expert Web Application Development services, crafting bespoke digital solutions tailored to your unique needs.",
    },
    {
      title: "DevOps Services",
      description:
        "Accelerate your development lifecycle and drive innovation with our top-tier DevOps consulting and services, empowering your team to automate workflows, enhance collaboration.",
    },
    {
      title: "UX/UI Design",
      description:
        "Transform your digital presence with our standout UX/UI Design services, crafting intuitive interfaces and captivating visuals to captivate audiences, drive engagement, and foster long-term growth.",
    },
    {
      title: "Mobile App Development",
      description:
        "Elevate your brand with our expert mobile app development services, creating innovative and user-friendly apps for Android and iOS platforms to engage your audience effectively.",
    },
    {
      title: "Software Maintenance",
      description:
        "Ensure your software stays optimized and secure with our comprehensive maintenance services, keeping your applications up-to-date and running smoothly for enhanced performance.",
    },
    {
      title: "Cloud based Services",
      description:
        "Embrace the power of cloud computing with our scalable solutions, providing secure data storage, seamless access, and efficient management for your business operations to thrive in the digital era.",
    },
  ];

  return (
    <div
      className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-8 py-12"
      style={{
        backgroundImage: `url(${contact.contactbg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      {services.map((service, index) => (
        <ServiceSection
          key={index}
          title={service.title}
          description={service.description}
        />
      ))}
    </div>
  );
};

export default ServicesList;
