import React,{useEffect} from 'react'
import Contact from '../../components/ContactUs'
import ContactUsForm from '../../components/ContactUs/ContactForm'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

const ContactUs = () => {
  useEffect(()=> {
    document.title = "Contact Us";
  }, [])
  return (
    <>
    <Header/>
    <Contact/>
    <ContactUsForm/>
    <Footer/>
    </>
  )
}

export default ContactUs