import React from "react";
import { about } from "../../../assets/about";

const HeroSections = () => {
  
  return (
    
    <div
      className="md:h-screen h-[350px]  relative  bg-black text-white"
      style={{
        backgroundImage: `url(${about.itservices})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="md:pl-28 md:pb-60 pl-12 w-[90%] h-[90%] pb-32">
        <h2 className="md:text-4xl text-2xl pb-4 pt-28 font-normal">Logistics software solutions</h2>
        <h2 className="md:text-6xl text-2xl font-semibold">
        Innovate, build, and launch <br /> our services excel
        </h2>
      </div>
    </div>
  );
};  

export default HeroSections;
