import React, { useEffect } from 'react'
import ProjectsSection from '../../components/Projects'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

const Projects
 = () => {
  useEffect(()=> {
    document.title = "Projects";
  }, [])
  return (
   <>
   <Header/>
   <ProjectsSection/>
   <Footer/>
   </>
  )
}

export default Projects
