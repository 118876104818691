import React from "react";
import { contact } from "../../../assets/contact";
import { useProject } from "../../../context/selectedServiceContext";
import { viewProjectsData } from "../../../data/viewprojectsdata";
import { Navigate } from "react-router-dom";

const HeadSection = () => {
  const { selectedProject } = useProject() || {}; // Access the selected project from the context
  const projectId = selectedProject || 1;

    // Filter the viewProjectsData array to find the selected project
  const selectedProjectData = viewProjectsData.find(
    (project) => project.id === projectId
  );

  if (!projectId) {
    return <Navigate to="/" />;
}

  return (
    <div
      className="md:h-screen  bg-[#ececec] flex flex-col items-center px-14"
      style={{
        backgroundImage: `url(${contact.contactbg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="">
        <h2 className=" md:text-[45px] text-[25px]   font-extrabold pt-10 text-[#000422]">
        {selectedProjectData.tagline1}
        </h2>
        <h2 className=" md:text-[45px] text-[25px] font-extrabold ">
          {selectedProjectData.tagline2}
        </h2>
      </div>
      <img src={selectedProjectData.heroimg} alt="" className="md:h-[70%] h-[40%] mt-8 md:object-center object-contain" />
    </div>
  );
};

export default HeadSection;
