import React, { useState } from "react";
import { HiOutlineArrowRight } from "react-icons/hi2";
import Modal from "./Modal";
import "./index.css";
import { contact } from "../../../assets/contact";

const JobSection = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleModalOpen = (position) => {
    setSelectedPosition(position);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const jobPositions = [
    "MERN Stack Intern",
    "AI ML Intern",
    "Front-end Developer",
    "Back-end Developer",
    "UI/UX Designer",
  ];

  const jobPositionRectangles = jobPositions.map((position, index) => (
    <div
      key={index}
      className="flex flex-row justify-between md:py-4 py-2 my-4 px-8 relative md:text-xl font-medium text-[#000422] bg-white shadow-lg"
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={handleMouseLeave}
    >
      <div className="font-medium md:text-lg text-[16px] ">{position}</div>
      {hoveredIndex === index && (
        <HiOutlineArrowRight
          className="text-2xl cursor-pointer w-10"
          onClick={() => handleModalOpen(position)}
        />
      )}
    </div>
  ));

  return (
    <div
      className="relative h-full  "
      style={{
        backgroundImage: `url(${contact.contactbg})`,
        backgroundSize: "cover",
      }}
    >
      <div className="  text-black flex flex-col justify-center items-center">
        <h2 className="md:text-3xl text-xl font-extrabold text-left mb-8  pt-10 text-[#000422] w-[90%]">
          Current job open positions
        </h2>
        <div className=" flex md:flex-row flex-col w-[90%]  ">
          <div className="md:w-1/2 flex flex-col  justify-center b cursor-pointer">
            {jobPositionRectangles}
          </div>
          <div className="md:w-1/2 h-full md:p-8  ">
            <div className="bg-white pt-10 flex justify-center drop-shadow-lg w-[100%] h-[90%] rounded-2xl">
              <img
                src={contact.career_vector}
                alt=""
                className=" rounded-lg  object-contain h-[385px] pb-20"
              />
              <div className="absolute inset-0 flex flex-col justify-end items-center  pb-10">
                <h2 className="md:text-3xl text-xl font-extrabold text-[#000422]">
                  Mail Us at
                </h2>
                <p className="md:text-2xl font-semibold text-[#000422]">
                  careers@wesolutize.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        title={selectedPosition !== null ? selectedPosition : ""}
      />
    </div>
  );
};

export default JobSection;
