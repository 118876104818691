import React from "react";
import { viewProductsData } from "../../data/viewproductsdata";
import { useProject } from "../../context/selectedServiceContext";
import { useNavigate } from "react-router-dom";

const ProductsSection = () => {
  const navigate = useNavigate();
  const { setProduct } = useProject(); // Access the setProject function from the context

  const handleViewProduct = (projectId) => {
    // Set the selected project using the context
    setProduct(projectId);
    console.log("1", projectId);
    // Redirect to the "View Projects" page

    if(projectId==1)
    {
      navigate("/solarproject")
    }
    else{
      navigate("/aboutProduct");
    }
  };

  return (
    <>
      <div className="md:h-full h-[350px] relative  bg-black text-white">
        <div className="md:pl-28 pl-[50px] pb-60 ">
          <h2 className="md:text-4xl text-2xl pb-4 md:pt-28 pt-14 font-medium">Products</h2>
          <h2 className="md:text-6xl text-3xl font-extrabold">
          Digital Transformations Solutions
          </h2>
        </div>
      </div>
      {/* First Overlay */}
      <div className="md:h-fit pb-16  md:-mt-32  flex flex-col  text-white gap-[69px]  items-center bg-gradient-to-r from-[#088FC7] to-[#612980] ">
        {viewProductsData.map((project, index) => (
          <div
            key={project.id}
            className={` flex grow   md:w-[80%] w-[90%] md:h-fit rounded-3xl md:mt-0 mt-4  bg-[#252525] bg-opacity-50 md:backdrop-blur-md backdrop ${
              index % 2 === 0 ? "md:flex-row flex-col" : "md:flex-row-reverse flex-col"
            }`}
          >
            {/* Image */}
            <div className="md:w-1/2 w-full md:m-16">
              <img
                src={project.image}
                alt={`Project ${project.id}`}
                className=" md:h-auto h-[300px] w-full md:w-auto   object-cover md:rounded-3xl rounded-t-3xl"
              />
            </div>
            {/* Text */}
            <div
              className={`md:w-1/2 w-full md:pt-16 pt-10 pb-6 p-2  ${
                index % 2 === 0 ? "md:pr-16 " : "md:pl-16"
              }`}
            >
              {/* heading */}
              <h2 className="md:text-3xl text-2xl md:font-bold">{project.heading}</h2>
              {/* descripption */}
              <p className="md:text-xl font-medium md:mt-10 mt-5">{project.text}</p>

              {/* sub columns  */}
              {/* <div className="grid grid-cols-2 md:mt-12 mt-8 ">
                <div className=" border-r-[white] border-r-2 col-span-1">
                  <p className="text-[20px] font-bold">
                    +{project.conversionRate}%
                  </p>
                  <p className="text-[18px] font-normal">
                    Higher Conversion Rate
                  </p>
                </div>
                <div className=" col-span-1 pl-[12px] ">
                  <p className="text-[20px] font-bold">{project.rating}</p>
                  <p className="text-[18px] font-normal">
                    Average Rating on Store
                  </p>
                </div>
              </div> */}
              {/* View Projects button */}
              <button
                className="bg-gradient-to-r from-[#088FC7] to-[#612980] text-white text-xl font-semibold md:w-[205px] md:h-[60px] mt-[53px] py-2 px-4 rounded-full md:float-none float-right"
                onClick={() => handleViewProduct(project.id)}
              >
                View Product
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProductsSection;
