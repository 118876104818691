import { about } from "../assets/about";

const yearData = [
  {
    year: 2018,
    image: about.img1,
    paragraphs: [
     "In 2018, Appasaheb Raul founded WeSolutize with a vision to contribute to the research field and support fellow researchers in achieving success. Drawing from his experience as a research fellow at the Indian Institute of Technology, Mumbai, he established a platform to bridge the gap between academia and industry. By connecting with seasoned professors and professionals, he identified the existing disparities within the research community and industry, laying the foundation for a venture dedicated to enhancing research practices and collaboration."
    ],
  },

  {
    year: 2020,
    image: about.img1,
    paragraphs: [
      "By 2020, WeSolutize had evolved into a thriving hub for researchers, offering valuable resources and fostering a culture of innovation and collaboration. Under Appasaheb Raul's leadership, the company  expanded its network, engaging with a diverse range of experts and organizations to further its mission. Through strategic partnerships and a commitment to excellence, WeSolutize has become a trusted ally for researchers, providing essential support and guidance to navigate the complexities of the research landscape.",
    ],
  },
  {
    year: 2022,
    image: about.img1,
    paragraphs: [
      "In 2022, leveraging the success and expertise of WeSolutize, a software development company was established as a subsidiary under the umbrella of WeSolutize. This new venture aimed to extend the company's impact by offering cutting-edge software solutions to a broader audience. With a focus on innovation and quality, the software development company quickly gained recognition for its tailored approach and commitment to client satisfaction.",
    ],
  },
  {
    year: 2024,
    image: about.img1,
    paragraphs: [
      "By 2024, the software development company had flourished, undertaking a diverse range of projects, including ERP and CRM-based web application software development. Through a blend of technical proficiency and industry insight, the company had earned a reputation for delivering robust and scalable solutions that met the evolving needs of businesses. With a growing portfolio of successful projects, the software development company had solidified its position as a key player in the software development industry, driving innovation and excellence in every endeavor.",
    ],
  },
  // Add data for other years similarly
];
export default yearData;
