import React,{useState,useEffect } from "react";
import { services } from "../../../assets/services";
import { useProject } from "../../../context/selectedServiceContext";
import { ProjectDataGenerator } from "../../../data/projectData";
import { Navigate } from "react-router-dom";
import "../../../../src/index.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';


const HeadContent = () => {
  const [activeBlock, setActiveBlock] = useState(0);
  const { selectedProject } = useProject();
  console.log("selected project:", selectedProject);
  const projectData = ProjectDataGenerator({selectedProject, activeBlock});
  console.log("projectData: ", projectData);



  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Call it once to set the initial state
    window.addEventListener("resize", handleResize); // Listen for window resize events

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener
    };
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  if (!selectedProject) {
    return <Navigate to="/" />;
}

  
  const handleBlockClick = (id) => {
    setActiveBlock(id === activeBlock ? 0 : id);

  };
  
  
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    
    
  };
  return (
    <>
      {/* Background */}
      <div className="md:h-screen h-[400px] relative bg-black text-white">
        <div className="md:pl-28 pl-14">
          <h2 className="md:text-4xl text-2xl pb-4 pt-[176px] font-medium">Case Studies</h2>
          <h2 className="md:text-6xl text-4xl font-extrabold">
            Optimize. Innovate. Disrupt
          </h2>
        </div>
      </div>

      



        <div className="md:h-full   md:-mt-40 text-white bg-gradient-to-r from-[#088FC7] to-[#612980]">
        <div className=" md:grid md:grid-cols-4  justify-center gap-5 items-center text-white px-14 ">
          {isSmallScreen ? (
            <Slider {...sliderSettings}>
              {projectData.map((project) => (
                <div
                  key={project.id}
                  className={`md:w-[283px] border rounded-3xl mt-10 flex flex-col p-4 mb-4 items-center justify-center backdrop-blur-md ${
                    activeBlock === project.id ? "md:h-[380px]" : "md:h-[346px]"
                  }`}
                  style={{
                    backgroundImage: project.id === activeBlock ? `url(${services.bg})` : "none",
                    backgroundColor: project.id === activeBlock ? "transparent" : "rgba(37, 37, 37, 0.5)",
                    backgroundSize: "cover",
                  }}
                  onClick={() => handleBlockClick(project.id)}
                >
                  <div className="flex justify-center items-center p-8 gap-5">
                  <img
                    src={project.image}
                    alt={`Project ${project.id}`}
                    className="object-cover md:h-14 h-10  md:w-14  "
                  />
                  <h2 className="md:text-3xl text-xl font-semibold text-center z-10">{project.heading}</h2>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            projectData.map((project) => (
              <div
                key={project.id}
                className={` rounded-3xl flex flex-col p-4  mb-4 items-center justify-center backdrop-blur-md ${
                  activeBlock === project.id ? "h-[380px]" : "h-[346px]"
                }`}
                style={{
                  backgroundImage: project.id === activeBlock ? `url(${services.bg})` : "none",
                  backgroundColor: project.id === activeBlock ? "transparent" : "rgba(37, 37, 37, 0.5)",
                  backgroundSize: "cover",
                }}
                onClick={() => handleBlockClick(project.id)}
              >
                <img
                  src={project.image}
                  alt={`Project ${project.id}`}
                  className="object-cover md:h-14 w-14 mt-16 mb-7"
                />
                <h2 className="md:text-2xl text-xl font-semibold pb-20 text-center z-10">{project.heading}</h2>
              </div>
            ))
          )}
        </div>
        {projectData
          .filter((project) => project.id === activeBlock || (!activeBlock && project.id === 1))
          .map((project) => (
            <div className=" md:mx-20 mx-10 h-auto pt-14" key={project.id}>
              <h2 className="md:text-[55px] text-2xl font-medium">{project.heading}</h2>
              <div className="md:flex items-start justify-between mt-16">
                <img
                  src={project.image2}
                  alt=""
                  className="md:h-[40%] md:w-[50%] object-cover"
                />
                <div className="md:text-[22px] text-[16px] font-normal text-justify text-pretty md:ml-28   ">
                  {/* Description */}
                  <p>{project.p1}</p>
                  <p className="mt-10">{project.p2}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default HeadContent;
