import React from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import HeroSection from "../../components/AIMLServices/HeroSections";
import ServicesList from "../../components/AIMLServices/ServicelistSection";
import HelpSection from "../../components/AIMLServices/HelpSection";

const AIMLServices = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <ServicesList />
      <HelpSection />
      <Footer />
    </>
  );
};

export default AIMLServices;
