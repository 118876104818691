import React, { useEffect } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import HeadContent from '../../components/Services/HeadContent'
import SectionThree from '../../components/Services/Section3'
import SectionFour from '../../components/Services/Section4'


const Services = () => {
  useEffect(()=> {
    document.title = "Services";
  }, [])
  return (
    <div>
      <Header/>
      <HeadContent/>
      <SectionThree/>
      <SectionFour/>
      <Footer/>
    </div>
  )
}

export default Services