export const contact ={
        contactbg: require("./contactbg.png"),
        mlhead: require("./mlhead.png"),
        ml_section2: require("./ml_section2.png"),
        ml_section4: require("./ml_section4.png"),
        usericon: require("./usericon.png"),
        career_bg: require("./career_bg.png"),
        career_discussion: require("./career_discussion.png"),
        career_vector: require("./career_vector.png"),
        solardryerbg:require("./solardryerbg.png"),
}