export const mldata = [
  {
    id: 1,
    title: "Deep Learning",
    list: [
      "Adaptable Systems",
      "Automated Troubleshooting",
      "Visual Assistant",
      " Bioinformatics",
    ],
  },
  {
    id: 2,
    title: "Predictive Analytics",
    list: [
      "Pattern and Trend Identification",
      "Behavior Prediction",
      "Customer Segmentation",
      " Demand Forecasting",
    ],
  },
  {
    id: 3,
    title: "Neural Network Development",
    list: [
      "Monitoring Services",
      "Clustering",
      "Fraud Detection",
      " Business Analysis",
    ],
  },
  {
    id: 4,
    title: "Data Automation",
    list: [
      "Architecture and Application Design",
      "Development and Testing",
      "Installation and Configuration",
    ],
  },
];
