import React,{useEffect} from 'react'
import HeroSection from '../../components/About/HeroSection'
import HowItStarted from '../../components/About/HowItStarted'
import WhoWeAre from '../../components/About/WhoWeAre'
import LeaderShip from '../../components/About/Leadership'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

const About = () => {
  useEffect(()=> {
    document.title = "Who We Are";
  }, [])
  return (
    <>
    <Header/>
    <HeroSection/>
    <HowItStarted/>
    <WhoWeAre/>
    <LeaderShip/>
    <Footer/>
    </>
    )
}

export default About