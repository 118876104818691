import logo from "../assets/logos";

export const logogrid = [
  {
    id: 1,
    src: logo.node,
  },
  {
    id: 2,
    src: logo.react,
  },
  {
    id: 3,
    src: logo.react_native,
  },
  {
    id: 4,
    src: logo.sql,
  },
  {
    id: 5,
    src: logo.mongodb,
  },
 
  {
    id: 6,
    src: logo.html,
  },
  {
    id: 7,
    src: logo.css,
  },
  {
    id: 8,
    src: logo.tailwind,
  },
  
  {
    id: 9,
    src: logo.bootstrap,
  },
  // Add more logo objects as needed
];

export const uiuxLogos = [
  {
    id: 1,
    src: logo.figma,
  },
  {
    id: 2,
    src: logo.photoshop,
  },
  {
    id: 3,
    src: logo.illustrator,
  },
  // Add more logos for UI/UX design services as needed
];

export const homelogo = [
  {
    id: 1,
    src: logo.godaddy,
  },
  {
    id: 2,
    src: logo.aws,
  },
  {
    id: 3,
    src: logo.leapswitch,
  },
  {
    id: 4,
    src: logo.cloudinary,
  },
  {
    id: 5,
    src: logo.onhost,
  },
  {
    id: 6,
    src: logo.hostinger,
  },
  // Add more logo objects as needed
];
