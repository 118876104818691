import React from "react";
import { about } from "../../../assets/about";
import VerticalScrollingText from "../VerticalText";

const HeroSection = () => {
  const imgStyle = {
    width: "100%", // 100% of the container width
    height: "100%", // 100% of the container height
    objectFit: "cover", // Cover the entire container
  };

  const textStyle = {
    fontFamily:'Mulish',
    position: "absolute",
    top: "50%", // Center text vertically
    left: "5%", // Adjust the left position as needed
    transform: "translateY(-50%)", // Center text vertically
    color: "white",
    textAlign: "left", // Align text to the left
    zIndex: 1, // Ensure text is above the image
  };

  return (
    <div className="h-screen bg-black"  style={{
      backgroundImage: `url(${about.hero})`,
      backgroundSize: "cover",
      backgroundPosition:"center"
    }}>

      {/* Text Container */}
      <div className="flex flex-row md:mt-0 -mt-[120px] w-[80%]" style={textStyle}>
        <h1 className="md:text-6xl text-3xl font-extrabold mb-4  ">
          We're here to make your project  <br />
          <span className="flex items-start gap-2 ">
            much <VerticalScrollingText />
          </span>
        </h1>
      </div>
    </div>
  );
};

export default HeroSection;
