import { createContext, useContext, useState } from "react";

const ProjectContext = createContext();

export const ProjectProvider = ({ children }) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProduct,setSelectedProduct]=useState(null);
  const setProject = (project) => {
    setSelectedProject(project);
    console.log("context", project);
  };

  const setProduct=(product)=>{
    setSelectedProduct(product)
    console.log("context", product);
  }

  return (
    <ProjectContext.Provider value={{ selectedProject, setProject ,selectedProduct,setProduct}}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useProject = () => {
  return useContext(ProjectContext);
};
