import React from "react";
import Carousel from "./Carousel";
import { contact } from "../../../assets/contact";

const SectionFive = () => {
  return (
    <div style={{
        backgroundImage: `url(${contact.contactbg})`,
        backgroundSize: "cover",
      }}>
      <div
        className="h-full bg-cover py-16 md:px-28 px-[40px] flex items-center   text-black"
        
      >
        <div className="md:w-3/4 h-[500px] md:h-auto flex flex-col justify-center ">
          <h2 className="font-medium md:text-4xl lg:w-[70%] text-2xl">
            First-Hand Opinions of Clients on
            Their Partnership Experience.
          </h2>
          <h2 className="font-normal text-xl pt-7 pr-4 text-justify">
            We are a software and mobile application development company with
            dedicated development team that ensures its expertise extends to
            offer a seamlessly productive and growth-oriented partnership to its
            clients.
          </h2>
        </div>
      </div>
      <div className="md:h-full ">
        <Carousel />
      </div>
    </div>
  );
};

export default SectionFive;
