import React, { useEffect } from 'react'
import ProductsSection from '../../components/Products'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

const Products
 = () => {
  useEffect(()=> {
    document.title = "Products";
  }, [])
  return (
   <>
   <Header/>
   <ProductsSection/>
   <Footer/>
   </>
  )
}

export default Products
