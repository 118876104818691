import React,{useState,useEffect,useRef} from "react";
import { contact } from "../../../assets/contact";
import { mldata } from "../../../data/mldata";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const HeadSection = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const sliderRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Set breakpoint for small screens
    };

    // Call handleResize on initial load
    handleResize();

    // Listen to window resize events
    window.addEventListener("resize", handleResize);

    // Clean up event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024, // Adjust breakpoint as needed
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // Adjust breakpoint as needed
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      {/* Background */}
      <div
        className="md:h-screen h-[400px] relative bg-black text-white"
        style={{
          backgroundImage: `url(${contact.mlhead})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="md:pl-28 pl-14">
          <h2 className="md:text-4xl text-2xl pb-4 md:pt-[176px] pt-[100px] font-medium">
            Build Powerful Business Strategies With
          </h2>
          <h2 className="md:text-6xl text-4xl font-extrabold">ML-Enabled Solutions </h2>
        </div>
      </div>

      {/* Projects Overlay */}
      <div className="h-full  md:-mt-40   text-white  bg-gradient-to-r from-[#088FC7] to-[#612980]">
        <div className="w-full grid lg:grid-cols-4 md:grid-cols-2 px-10 justify-center gap-5 items-center  pb-32 text-white ">
        {mldata.map((project) => {
          return(
            <div
              key={project.id}
              className="w-[100%] h-[100%]  rounded-3xl flex flex-col p-4 mb-4 md:mt-0 mt-12 items-center justify-center md:backdrop-blur-md bg-[#252525] bg-opacity-50 md:h-[325px]"
            >
              {/* Heading */}
              <h2 className="md:text-2xl text-xl font-extrabold md:pb-12 pb-8 text-center ">
                {project.title}
              </h2>
              {/* List of items */}
              <ul className="text-left list-disc pl-4">
                {project.list.map((item, index) => (
                  <li key={index} className="py-2 text-lg font-medium">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          )
        })}
        </div>
        
        </div>
    </>
  );
};

export default HeadSection;

