import React, { useRef, useState } from 'react';
import { FaMapPin } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { MdMailOutline } from "react-icons/md";
import emailjs from '@emailjs/browser';
import { contact } from "../../../assets/contact";
const ContactUsForm = () => {
  const form = useRef();
  const [formData,setFormData]=useState({
    name:'',
    email:'',
    contact:'',
    website:'',
    subject:'',
    message:'',
  })

  const handleChange=(e)=>{
    const {name,value}=e.target;
    setFormData((prev)=>({
      ...prev,
      [name]:value
    }))
  }


  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_7s3fpxr', 'template_jven5yx', form.current, {
        publicKey: 'pdRBXUJNx8kfBPPFK',
      })
      
      .then(
        () => {
          console.log('SUCCESS!');
          alert("Form Submitted Successfully")
        },
        (error) => {
          console.log('FAILED...', error.text);
          alert("Form Not Submit")
        },
      );

    setFormData({
      name:'',
      email:"",
      contact:'',
      subject:'',
      website:'',
      message:''
    })
  };
  return (
    <>
      <div
        className="h-full flex justify-center items-center"
        style={{
          backgroundImage: `url(${contact.contactbg})`,
          backgroundSize: "cover",
        }}
      >
        <div className="flex justify-center w-full ">
          <div className="flex flex-col  w-[80%] gap-4 items-center">
            {/* Heading */}
            <h2 className="md:text-[45px] text-[25px] font-extrabold text-[#000422] md:my-14 my-8">
              Get in Touch
            </h2>
            <form ref={form} onSubmit={sendEmail} className="w-full flex flex-col justify-center items-center">
              {/* First row: Name and Email */}
            <div className="md:flex w-full gap-5 mb-4">
              <input
                type="text"
                placeholder="Name"
                name='name'
                value={formData.name}
                onChange={handleChange}
                className="border p-2 flex-grow rounded-xl placeholder-[#434242] md:placeholder:text-lg md:mb-0 mb-4 font-normal md:h-16 md:w-0 w-full border-[#A49F9F]"
              />
              <input
                type="email"
                placeholder="Email"
                name='email'
                value={formData.email}
                onChange={handleChange}
                className="border p-2 flex-grow rounded-xl placeholder-[#434242] md:placeholder:text-lg font-normal md:h-16 md:w-0 w-full border-[#A49F9F]"
              />
            </div>

            {/* Second row: Phone Number and Website */}
            <div className="md:flex w-full gap-5 mb-4">
              <input
                type="tel"
                placeholder="Phone Number"
                name='contact'
                value={formData.contact}
                onChange={handleChange}
                className="border p-2 flex-grow rounded-xl placeholder-[#434242] md:mb-0 mb-4 md:h-16 md:w-0 w-full md:placeholder:text-lg font-normal border-[#A49F9F]"
              />
              <input
                type="url"
                placeholder="Website"
                name='website'
                value={formData.website}
                onChange={handleChange}
                className="border p-2 flex-grow rounded-xl placeholder-[#434242] md:h-16 md:w-0 w-full md:placeholder:text-lg font-normal border-[#A49F9F]"
              />
            </div>

            {/* Third row: Subject */}
            <input
              type="text"
              placeholder="Subject"
              name='subject'
              value={formData.subject}
              onChange={handleChange}
              className="border p-2 w-full rounded-xl placeholder-[#434242] md:h-16 md:placeholder:text-lg font-normal border-[#A49F9F] mb-4"
            />

            {/* Fourth row: Message */}
            <textarea
              rows="4"
              placeholder="Your Message"
              name='message'
              value={formData.message}
              onChange={handleChange}
              className="border p-2 w-full rounded-xl h-36 placeholder-[#434242] md:placeholder:text-lg font-normal border-[#A49F9F]"
            />

            {/* Submit Button */}
            <button className="bg-gradient-to-r from-[#088FC7] to-[#612980] text-white md:text-xl font-semibold md:w-[200px] w-[150px] md:h-[65px] h-[40px] md:mt-14 mt-8 py-2 px-4 rounded-full ">
              Submit
            </button>
            </form>

            {/* Additional Divs */}
            <div className="md:flex justify-between w-full h-[30%] my-11 ">
              {/* Div 1 */}
              <div className="flex flex-col md:w-[30%]  rounded-2xl border p-4 border-[#A49F9F] md:mb-0 mb-4">
                <div className="flex items-center mb-2 text-lg font-semibold">
                  {/* Flex container for icon and text */}
                  <FaMapPin className="mr-2 text-[#C70000]" />
                  {/* MapPin icon with margin */}
                  <h3 className="text-lg font-semibold text-[#434242]">
                    Head Office
                  </h3>
                </div>
                <p className="pl-6 mt-2 text-lg text-[#434242] font-normal">
                  Office 210, City center - Kolte Patil, Hinjewadi - Phase 1,
                  Pune - 411057, Maharashtra, India
                </p>
              </div>

              {/* Div 2 */}
              <div className="flex flex-col md:w-[30%]  rounded-2xl border p-4 border-[#A49F9F] md:mb-0 mb-4">
                <div className="flex items-center mb-2 text-lg font-semibold">
                  {/* Flex container for icon and text */}
                  <FaMapPin className="mr-2 text-[#C70000]" />
                  {/* MapPin icon with margin */}
                  <h3 className="text-lg font-semibold text-[#434242]">
                    Division office
                  </h3>
                </div>
                <p className="pl-6 mt-2 text-lg text-[#434242] font-normal">
                  Blue Ocean, MMCIII Center, MMCOE Karvenagar, Pune - 411052,
                  India
                </p>
              </div>

              {/* Div 3 */}
              <div className="flex flex-col md:w-[30%]  rounded-2xl border p-4  border-[#A49F9F]">
                <div className="flex items-center mb-2 text-lg font-semibold">
                  {/* Flex container for icon and text */}
                  <IoMdCall className="mr-2   text-[#00C750]" />
                  {/* MapPin icon with margin */}
                  <h3 className="text-lg font-semibold text-[#434242]">
                    Call Us
                  </h3>
                </div>
                <p className="pl-6 md:mt-2 text-lg text-[#434242] font-normal">
                  020 6828 0765
                </p>
                <div className="flex items-center md:mt-8 mb-2 text-lg font-semibold">
                  {/* Flex container for icon and text */}
                  <MdMailOutline className="mr-2  text-[#355DA4]" />
                  {/* MapPin icon with margin */}
                  <h3 className="text-lg font-semibold text-[#434242]">
                    Mail Us
                  </h3>
                </div>
                <p className="pl-6 md:mt-1 text-lg text-[#434242] font-normal ">
                    info@wesolutize.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsForm;
