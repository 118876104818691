import React from "react";
import { contact } from "../../../assets/contact";
import { softwaredata } from "../../../data/software_data";
import { useProject } from "../../../context/selectedServiceContext";
import { Navigate, useNavigate } from "react-router-dom";

const SectionThree = () => {
  const { selectedProject } = useProject();
  const navigate = useNavigate();
  if (!selectedProject) {
    return <Navigate to="/" />;
  }

  const filteredData = softwaredata.find(
    (item) => item.page === selectedProject
  );
  return (
    <div
      className="h-full bg-cover md:py-16 md:px-28 py-8 px-14 font-medium md:text-5xl text-3xl md:flex items-center justify-center"
      style={{
        backgroundImage: `url(${contact.contactbg})`,
        backgroundPosition: "center",
      }}
    >
      {filteredData && (
        <div className="md:w-3/4 ">
          <h2>{filteredData.tagline}</h2>
        </div>
      )}
      <div className="md:w-1/4 flex md:flex-col justify-center items-center md:mt-0 mt-4 gap-6">
        <button
          className=" text-black md:text-xl text-sm font-semibold md:w-[205px] md:h-[60px] w-[170px] h-[35px] py-2 md:px-4 rounded-full border-black border"
          onClick={() => navigate("/contactus")}
        >
          Know More
        </button>
        <button
          className="bg-gradient-to-r from-[#088FC7] to-[#612980] text-white md:text-xl text-sm font-semibold md:w-[205px] md:h-[60px] w-[170px] h-[35px]  py-2 md:px-4 rounded-full "
          onClick={() => navigate("/contactus")}
        >
          Schedule a call
        </button>
      </div>
    </div>
  );
};

export default SectionThree;
