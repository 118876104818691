import React, { useState } from "react";
import { CgArrowTopRight } from "react-icons/cg";
import {
  AIMLServices,
  SoftwareDevelopmentServices,
  aimlData,
  softwareDevData,
} from "../../../data/home_section3_data";

const SectionThree = () => {
  const [activeState, setActiveState] = useState("AI/ML");

  const toggleSection = (section) => {
    setActiveState(section);
  };

  const renderContent = (section) => {
    if (activeState === section) {
      return (
        <div className="pt-4 md:grid grid-cols-2 gap-x-16  ">
          {section === "AI/ML"
            ? AIMLServices.map((service, index) => (
                <ServiceBlock
                  key={index}
                  title={service.title}
                  description={service.description}
                />
              ))
            : SoftwareDevelopmentServices.map((service, index) => (
                <ServiceBlock
                  key={index}
                  title={service.title}
                  description={service.description}
                />
              ))}
        </div>
      );
    }
  };

  const ServiceBlock = ({ title, description }) => {
    const [isRotated, setIsRotated] = useState(false);

    return (
      <div className=" md:h-auto">
        <div
          className="flex flex-row justify-between   pt-9 items-center pb-5"
          onMouseEnter={() => setIsRotated(true)}
          onMouseLeave={() => setIsRotated(false)}
        >
          <h2
            className={` md:text-3xl text-xl font-normal ${
              isRotated ? "bg-gradient-to-r from-[#088FC7] to-[#612980] text-transparent bg-clip-text cursor-pointer" : "text-black"
            }`}
          >
            {title}
          </h2>
          <a href={activeState === "AI/ML" ? "/aimlservices" : "/itservices"}>
            <CgArrowTopRight
              className={`md:w-10 w-5 md:h-10 h-5 cursor-pointer transform transition duration-300 ${
                isRotated ? "rotate-45 " : "rotate-0"
              }`}
              style={{ color: isRotated ? "#6801EB" : "black" }}
            />
          </a>
        </div>
        <div>
          <p className="text-black font-light md:text-xl text-[18px] pb-7">{description}</p>
        </div>
        <div className="border-b border-gray-300 pt-3"></div>
      </div>
    );
  };

  return (
    <div  id="services" className="h-full  md:px-28 px-[40px] py-12  ">
      <div className="md:grid grid-cols-2 flex md:justify-center items-center md:text-3xl  h-[50px] text-sm font-medium">
        <div
          className={`text-black w-full h-full  pb-2 text-center   cursor-pointer ${
            activeState === "AI/ML"
              ? " border-b-[6px] border-[#3952AF] "
              : "border-b-2 border-[#AE9C9C]"
          }`}
          onClick={() => toggleSection("AI/ML")}
        >
          AI / ML Services
        </div>
        <div
          className={`text-black w-full h-full pb-2  text-center cursor-pointer ${
            activeState === "SoftwareDevelopment"
              ? "border-b-[6px] border-[#3952AF] "
              : "border-b-2 border-[#AE9C9C]"
          }`}
          onClick={() => toggleSection("SoftwareDevelopment")}
        >
          Software Development
        </div>
      </div>

      <div className="md:block hidden h-fit px-12 mt-10 mb-11 rounded-3xl text-white bg-gradient-to-r from-[#088FC7] to-[#612980] bg-opacity-80">
        <h2 className="md:text-3xl 2xl:text-4xl font-medium pt-8 pb-5">
          {activeState === "AI/ML" ? aimlData.title : softwareDevData.title}
        </h2>
        <p className="text-xl 2xl:text-2xl font-medium text-[#F5F5F5] pb-12">
          {activeState === "AI/ML"
            ? aimlData.description
            : softwareDevData.description}
        </p>
      </div>

      {/* Render the content of Active Section */}
      {renderContent("AI/ML")}
      {renderContent("SoftwareDevelopment")}
    </div>
  );
};
export default SectionThree;
