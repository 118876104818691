import React from "react";
import { aiservicesdata } from "../../../data/ai_section4_data";
import { contact } from "../../../assets/contact";

const SectionFour = () => {
  return (
    <div
      className="md:h-full text-white pt-20 md:px-28 px-14"
      style={{
        backgroundImage: `url(${contact.ml_section4})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
      }}
    >
        <h2 className="md:text-5xl text-3xl font-normal md:px-10 ">How Can Machine Learning Development<br/> Solutions Benefit Your Business?</h2>
      <div className="w-full  flex md:flex-row flex-col flex-wrap justify-center gap-5 items-center pt-24  pb-32  text-white ">
        {aiservicesdata.map((project) => (
         <div
         key={project.id}
         className="md:w-[373px] md:h-[317px] rounded-3xl flex flex-col p-4 mb-4   md:backdrop-blur-md bg-[#252525] bg-opacity-50"
       >
        <img src={contact.usericon} alt="" className="md:size-[45px] size-[35px] my-5"/>
         {/* Heading */}
         <h2 className="md:text-2xl text-xl font-bold mb-4 pt-2 text-left">{project.title}</h2>
         <p className="flex-grow text-sm font-normal pb-10 text-justify">{project.para}</p>
       </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFour;
