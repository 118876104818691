export const softwaredata = [
  {
    id: 1,
    title: "Web Development Services",
    page:"Web Development",
    tagline: "Leading Web Development Services",
  },
  {
    id: 2,
    page: "Mobile App Development",
    title: "Mobile App Development",
    tagline: "Looking For A Mobile App Development Company?",

  },
  {
    id: 3,
    page: "UX/UI Design",
    title: "UX/UI Design Services",
    tagline: "Want to Get Top-notch UI and UX Design Services?",

    
  },
];
