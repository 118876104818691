import React from "react";
import { contact } from "../../../assets/contact";
import { useProject } from "../../../context/selectedServiceContext";
import { viewProductsData } from "../../../data/viewproductsdata";
import { Navigate } from "react-router-dom";

const HeadSection = () => {
  const { selectedProduct } = useProject(); // Access the selected project from the context
    console.log("product:", selectedProduct);

    // Filter the viewProjectsData array to find the selected project
  const selectedProductData = viewProductsData.find(
    (product) => product.id === selectedProduct
  );

  if (!selectedProduct) {
    return <Navigate to="/" />;
}

  return (
    <div
      className="md:h-screen h-[50vh]  bg-[#F2F2F2] flex flex-col items-center px-14"
      style={{
        backgroundImage: `url(${contact.solardryerbg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="text-white">
        <h2 className=" md:text-[30px] text-[20px]   font-extrabold pt-10 ">
        {selectedProductData.tagline1}
        </h2>
        <h2 className=" md:text-[45px] text-[25px]  font-extrabold ">
          {selectedProductData.tagline2}
        </h2>
      </div>
      {/* <img src={selectedProductData.heroimg} alt="" className="md:h-[70%] h-[40%] mt-8 md:object-center object-contain" /> */}
    </div>
  );
};

export default HeadSection;
