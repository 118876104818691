import React from "react";
import HeadSection from "../../components/ViewProduct/HeadSection";
import DataSection from "../../components/ViewProduct/DataSection";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const ViewProduct = () => {
  return (
    <>
    <Header/>
      <HeadSection />
      <DataSection/>
      <Footer/>
    </>
  );
};

export default ViewProduct;
