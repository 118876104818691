import React from 'react';
import TextTransition from 'react-text-transition';

const TEXTS = ['easier', 'simpler', 'accessible'];

const VerticalScrollingText = () => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <h1 >
      <TextTransition  springConfig={{ mass: 2, tension: 60, friction: 15 }}>{TEXTS[index % TEXTS.length]}</TextTransition>
    </h1>
  );
};

export default VerticalScrollingText