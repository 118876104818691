import React, { useState } from "react";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { contact } from "../../../assets/contact";
import { useNavigate } from "react-router-dom";

const ServiceSection = ({ title, description }) => {
  const [hovered, setHovered] = useState(false); // State to track hover
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setHovered(true); // Set hovered to true on mouse enter
  };

  const handleMouseLeave = () => {
    setHovered(false); // Set hovered to false on mouse leave
  };

  const handleClick = () => {
   
      navigate("/aiservices");
   
  };


  return (
    <div
      className={`w-full md:h-72 mx-auto md:mt-10  border-b-2 md:border-b-0 md:rounded-2xl rounded-none p-6 relative cursor-pointer ${hovered ? 'bg-gray-200 rounded-2xl' : ' bg-white bg-opacity-15 md:backdrop-blur-lg '} transition-colors`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <h2 className={`md:text-3xl text-xl font-medium ${hovered ? ' bg-gradient-to-r from-[#088FC7] to-[#612980] text-transparent bg-clip-text' : 'text-black'} transition-colors`}>{title}</h2>
      <div className="w-full md:h-8 h-4  border-l-4 my-2 mx-auto" style={{ borderLeftColor: hovered ? 'rgba(8, 143, 199)' : '#D9D9D9' }}></div>
      <p className="mt-2 text-sm font-normal text-[#000422] ">
        {description}
      </p>
      {/* Right arrow icon */}
      {hovered && (
        <div className="absolute bottom-4 left-[10%] transform -translate-x-1/2">
          <HiOutlineArrowLongRight className="h-12 w-12 "   style={{ color: hovered ? 'rgba(8, 143, 199)' : 'black' }}
/>
        </div>
      )}
    </div>
  );
};

const ServicesList = () => {
  
  const services = [
    { title: "Machine Learning", description: "From predictive analytics to pattern recognition, we offer end-to-end ML development services that guide you in data-driven decision-making, improve operational efficiency, and reduce downtime." },
    { title: "Chatbot Development", description: "Revolutionize customer engagement with our AI-driven chatbot development services. Provide instant responses 24/7, gather valuable consumer data, and enhance customer interactions. " },
    { title: "Image Processing", description: "Elevate your image processing capabilities with our advanced solutions. From data augmentation techniques to sophisticated model training, we optimize your image-based AI projects for superior performance. " },
    { title: "Data Mining", description: "Uncover hidden patterns and insights in your data with our comprehensive data mining services. From image annotation to NLP data processing, we help you extract valuable information to make informed decisions." },
    { title: "Predictive Analysis", description: "We create predictive analytics solutions using advanced statistical models and machine learning algorithms, empowering businesses to foresee outcomes, identify opportunities, reduce downtime, and optimize strategies. " },
    { title: "Voice based AI", description: "Transform the way you interact with customers using   our voice-based AI services. Gain valuable insights   through speech recognition analytics, knowledge extraction, and personalized engagements. " },
    { title: "Natural Language Processing", description: "Leveraging advanced NLP techniques, we help businesses with text analysis and summarization, multi-lingual support, chatbot and conversational AI development, etc., " },
    { title: "AI Consulting & Integration", description: "Helping businesses harness the power of AI potential by offering AI integration services. Be it API integration, cloud AI enablement, or custom AI integration, we ensure that it's done seamlessly." },
    { title: "Data Annotation", description: "Unlock the power of AI with precise data annotation services. Our expert team meticulously labels and categorizes your data, ensuring top-notch training datasets for your machine learning models." },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-8 py-12" style={{
      backgroundImage: `url(${contact.contactbg})`,
      backgroundPosition: "center",
      backgroundSize:"cover"
      
    }}>
      {services.map((service, index) => (
        <ServiceSection
          key={index}
          title={service.title}
          description={service.description}
        />
      ))}
    </div>
  );
};

export default ServicesList;
