import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { projectData } from "../../../data/home_section4_data";
import { useProject } from "../../../context/selectedServiceContext";

const SectionFour = () => {
  const { setProject } = useProject(); // Access the setProject function from the context
  const navigate = useNavigate();
  const [hoveredProject, setHoveredProject] = useState(null);

  const handleContactUsNavigation = () => {
    navigate("/contactus");
  };

  const handleMouseEnter = (index) => {
    setHoveredProject(index);
  };

  const handleMouseLeave = () => {
    setHoveredProject(null);
  };

  const handleLearnMore = (projectId) => {
    setProject(projectId);
    navigate("/aboutproject");
  };

  return (
    <div className="h-full bg-gradient-to-r from-[#088FC7] to-[#612980] bg-opacity-80 text-white text-left py-12 md:px-28 px-[40px]">
      <h2 className="md:text-4xl text-2xl font-bold mb-7">Our Recent Work</h2>
      <p className="md:text-xl font-medium ">
        Our Edge computing solutions solution and services have been helping
        businesses across
        <br /> the world to mitigate modern business challenges with a modern
        digital-first approach.
      </p>
      {/* First Row block */}
      <div className="md:grid grid-cols-3 gap-4 mt-20 flex flex-wrap">
        {projectData.map((project, index) => (
          <div
            key={index}
            className={`relative column `}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="column relative ">
              <figure className="relative ">
                <img
                  src={project.image}
                  alt={project.title}
                  className={`md:h-full  md:w-full object-cover rounded-3xl `}
                />
                <div
                  className={`absolute inset-0 lg:px-10 px-2 pt-20 bg-opacity-50 overflow-hidden rounded-3xl flex flex-col justify-between ${
                    hoveredProject === index ? "backdrop-blur-lg" : ""
                  }`}
                >
                  <div className="">
                    <h1 className="text-lg  font-medium pb-4 text-white">
                      {project.category}
                    </h1>
                    <p className="text-2xl font-semibold  text-white">
                      {project.description}
                    </p>
                  </div>
                  {hoveredProject === index && (
                    <div
                      className="absolute  bottom-4 bg-[#60789] left-0 right-0 px-10 pb-10 cursor-pointer "
                      onClick={() => handleLearnMore(project.id)}
                    >
                      <p className="font-semibold text-white">Learn More</p>
                    </div>
                  )}
                </div>
              </figure>
            </div>
          </div>
        ))}
      </div>

      <div className="grid md:grid-cols-3 grid-cols-2 gap-4 mt-10 ">
        <div className="bg-[#002286] bg-opacity-85 rounded-3xl flex flex-col justify-center md:px-8 md:h-full h-[100px] md:py-11 py-4 text-white text-center">
          <h1 className="md:text-5xl text-[20px] font-bold">60+</h1>
          <h2 className="md:font-medium md:text-2xl text-[14px]">Projects Covered</h2>
        </div>
        <div className="bg-[#002286] bg-opacity-85 rounded-3xl flex flex-col justify-center md:h-full h-[100px]  md:px-8 md:py-11 py-4  text-white text-center">
          <h1 className="md:text-5xl text-[20px] font-bold">200+</h1>
          <h2 className="md:font-medium md:text-2xl text-sm">Happy Customers</h2>
        </div>
        <div className="bg-white rounded-3xl md:h-full h-[100px] flex flex-col justify-center items-center  md:px-8 md:py-11 py-4 text-black text-center">
          <h1 className="md:pb-5  md:text-2xl text-sm md:font-medium mb-2">Want To Start a Project</h1>
          <button
            onClick={handleContactUsNavigation}
            type="button"
            className="text-white md:h-[48px] md:w-[130px] h-[30px] w-[80px] p-2 text-[12px] flex justify-center items-center font-semibold bg-gradient-to-r from-[#088FC7] to-[#612980] md:font-medium rounded-full md:text-lg md:px-5 md:py-2.5"
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default SectionFour;
