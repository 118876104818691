import React from 'react';

import HeroSections from '../../components/ItServices/HeroSections';
import ServicesList from '../../components/ItServices/ServicelistSection';
import CustomDiv from  '../../components/ItServices/HelpSection'
import Header from '../../components/Header';
import Footer from '../../components/Footer';



const ITServices = () => {
  return (
    <>
    <Header/>
    <HeroSections/>
    <ServicesList/>
    <CustomDiv/>
    <Footer/>
 
    
    </>

  );
};

export default ITServices;
