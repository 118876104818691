import React from "react";
import HeadSection from "../../components/ViewProject/HeadSection";
import DataSection from "../../components/ViewProject/DataSection";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const ViewProject = () => {
  return (
    <>
    <Header/>
      <HeadSection />
      <DataSection/>
      <Footer/>
    </>
  );
};

export default ViewProject;
